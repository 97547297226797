@import "../../../../../colors";
@import "../../../../../mixins";

.header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
}

.headerTitle {
  flex: 1;
  color: $dt-darkBlue;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.idContainer {
  padding: 0px 20px;
  margin: 0 20px;
  border-width: 0 1px;
  border-style: solid;
  border-color: $dt-border;
}

.linkContainer {
  padding: 0 20px;
}

.flexSpacer {
  flex: 1;
}

.qrContainer {
  padding: 0 20px;
  display: flex;
  align-items: center;

  .qrToggler {
    cursor: pointer;
    font-size: 32px;
    color: $dt-darkBlue;
  }
}

.headerLabel {
  color: $dt-grey-3;
  font-size: 12px;
  font-weight: bold;
}

.headerVal {
  color: $dt-darkBlue;
  font-size: 20px;
  font-weight: bold;

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.badge {
  margin-bottom: 10px;
  margin-right: 10px;
  padding: 6px 8px;
  background: transparent;
  font-size: 14px;
  font-weight: normal;
  color: #444444;
  border: 1px solid #9faaae;
  border-radius: 5px;
}

.channelNotificationsContainer {
  display: flex;
  align-items: center;
  gap: 5px;
}

.channelNotificationsToggleLabel {
  text-transform: uppercase;
  color: $dt-grey-3;
  font-size: 14px;

  &:not(:first-child) {
    margin-left: 15px;
  }
}