.LoadingButton {
  position: relative;
}

.buttonText {
  visibility: visible;
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
}

.isLoading {
  .buttonText {
    visibility: hidden;
  }

  .loaderWrapper {
    visibility: visible;
  }
}