#FormResultsFilterBar .filterWrapper {
  display: inline
}

#FormResultsFilterBar .filterWrapper span {
  font-size: 10px
}

#FormResultsFilterBar .filterWrapper select {
  font-size: 10px;
  height: 25px;
  padding: 0px 0.75rem
}

#FormResultsFilterBar .btn-secondary {
  margin-left: 10px
}

#FormResultsFilterBar .responsesBadge {
  margin-left: 10px
}