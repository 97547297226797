@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

#OrtecLoader:not(.icon) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1060
}

#OrtecLoader:not(.icon) .ortecLoaderWrapperExternal {
  background-color: rgba(0, 0, 0, 0.6);
  text-align: center;
  position: absolute;
  top: 20%;
  left: 50%;
  -webkit-transform: translate(-50%, -20%);
  transform: translate(-50%, -20%);
  border-radius: 50%
}

#OrtecLoader:not(.icon) .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal {
  background-color: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

#OrtecLoader:not(.icon) .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal .ortecLoader:before {
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 50%;
  border-style: solid;
  border-color: #009BC2;
  border-top-color: #96BF0D;
  border-right-color: #ee7d11;
  border-bottom-color: #AB0076;
  -webkit-animation: spinner 0.6s linear infinite;
  animation: spinner 0.6s linear infinite
}

#OrtecLoader:not(.icon).fullPage {
  position: fixed;
  width: 100vw;
  height: 100vh;
}

#OrtecLoader:not(.icon).fullPage .ortecLoaderWrapperExternal {
  top: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  height: 160px;
  line-height: 160px;
}

#OrtecLoader:not(.icon).fullPage .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal {
  width: 80px;
  height: 80px;
}

#OrtecLoader:not(.icon).fullPage .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal .ortecLoader:before {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-width: 4px;
}

#OrtecLoader:not(.icon).large .ortecLoaderWrapperExternal {
  width: 160px;
  height: 160px;
  line-height: 160px
}

#OrtecLoader:not(.icon).large .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal {
  width: 80px;
  height: 80px
}

#OrtecLoader:not(.icon).large .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal .ortecLoader:before {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-width: 4px
}

#OrtecLoader:not(.icon).medium .ortecLoaderWrapperExternal {
  width: 80px;
  height: 80px;
  line-height: 80px
}

#OrtecLoader:not(.icon).medium .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal {
  width: 40px;
  height: 40px
}

#OrtecLoader:not(.icon).medium .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal .ortecLoader:before {
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-width: 2px
}

#OrtecLoader:not(.icon).small .ortecLoaderWrapperExternal {
  width: 48px;
  height: 48px;
  line-height: 48px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

#OrtecLoader:not(.icon).small .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal {
  width: 24px;
  height: 24px
}

#OrtecLoader:not(.icon).small .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal .ortecLoader:before {
  width: 12px;
  height: 12px;
  margin-top: -6px;
  margin-left: -6px;
  border-width: 1px
}

#OrtecLoader.icon {
  display: inline-block;
  width: 24px;
  height: 24px
}

#OrtecLoader.icon .ortecLoaderWrapperExternal {
  width: 100%;
  height: 100%
}

#OrtecLoader.icon .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal {
  width: 100%;
  height: 100%
}

#OrtecLoader.icon .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal .ortecLoader {
  width: 100%;
  height: 100%;
  position: relative
}

#OrtecLoader.icon .ortecLoaderWrapperExternal .ortecLoaderWrapperInternal .ortecLoader:before {
  width: 100%;
  height: 100%;
  border-width: 1px;
  content: "";
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  border-style: solid;
  border-color: #009BC2;
  border-top-color: #96BF0D;
  border-right-color: #ee7d11;
  border-bottom-color: #AB0076;
  -webkit-animation: spinner 0.6s linear infinite;
  animation: spinner 0.6s linear infinite
}

#OrtecLoader.loaderContainer {
  border: 0px !important
}