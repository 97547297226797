@import "../../../../colors";

.Articles {
  position: relative;
  min-height: 100px;
  margin-top: 20px;

  :global {
    i.status {
      font-size: 10px;

      &.status-visible {
        color: $dt-green;
      }

      &.status-partial {
        color: $dt-blue;
      }

      &.status-hidden {
        color: #d0021b;
      }
    }
  }
}