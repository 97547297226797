@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#EngagementPromotersStats {
  position: relative;
  height: 180px !important;
  margin-bottom: 20px
}

@media (min-width: 1200px) {
  #EngagementPromotersStats {
    height: 360px !important;
    margin-bottom: 0px
  }
}

#EngagementPromotersStats .horizontalDevider {
  width: 100%;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  background: #E5E5E5;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none
}

@media (min-width: 1200px) {
  #EngagementPromotersStats .horizontalDevider {
    display: block
  }
}

#EngagementPromotersStats .verticalDevider {
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  background: #E5E5E5;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: none
}

@media (min-width: 1200px) {
  #EngagementPromotersStats .verticalDevider {
    display: block
  }
}

#EngagementPromotersStats .infoMessage {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0;
  font-size: 0.8em;
  color: #ee7d11;
  padding: 5px;
  text-align: center;
  background: #ffffff;
  opacity: 0.6;
  visibility: hidden
}

#EngagementPromotersStats .infoMessage.top-left {
  bottom: 50%;
  width: 50%
}

#EngagementPromotersStats .infoMessage.bottom-left {
  bottom: 0;
  width: 50%
}

#EngagementPromotersStats .infoMessage.bottom-right {
  bottom: 0;
  left: 50%;
  width: 50%
}

#EngagementPromotersStats .infoMessage.additional-bottom-left {
  bottom: 30px;
  width: 50%;
  color: #444;
  font-style: italic
}

#EngagementPromotersStats:hover .infoMessage {
  visibility: visible;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

#EngagementPromotersStats>.row {
  height: 100%
}

#EngagementPromotersStats>.row>div:not(:first-child) {
  border-left: 1px solid #E5E5E5
}

@media (min-width: 1200px) {
  #EngagementPromotersStats>.row>div:not(:first-child) {
    border-left: unset
  }
}

#EngagementPromotersStats .StatBox {
  text-align: center;
  font-weight: bold;
  color: #444;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 5px
}

#EngagementPromotersStats .StatBox .valueDiv {
  font-size: 48px;
  letter-spacing: 0.7px;
  line-height: 72px
}

#EngagementPromotersStats .StatBox .valueDiv .scaletext-wrapper {
  overflow: visible !important
}

#EngagementPromotersStats .StatBox .textDiv {
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  height: 15px
}

#EngagementPromotersStats .forceIconSize img {
  height: 24px
}