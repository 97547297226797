.TabPageNavigation .nav-link {
  display: flex;
  flex-direction: row;
  text-transform: uppercase;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 12px;
  color: #9faaae !important;
  letter-spacing: 0.5px;
  border: none !important
}

.TabPageNavigation .nav-link:hover {
  border: none !important;
  cursor: pointer
}

.TabPageNavigation .nav-link.active {
  border: none !important;
  color: #002e67 !important;
  background-color: transparent !important;
  position: relative
}

.TabPageNavigation .nav-link.active::after {
  border-radius: 2px 2px 0 0;
  background-color: #002e67;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 4px;
  width: 100%;
  content: ''
}

.TabPageNavigation .nav-link.disabled {
  cursor: not-allowed
}


