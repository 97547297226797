#ReportTable .titleDiv {
  color: #002e67;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-left: 10px
}

#ReportTable tbody tr:hover {
  cursor: pointer
}

#ReportTable tbody tr:hover .hoverAction i {
  visibility: visible
}

#ReportTable tbody td {
  min-height: 55px;
  vertical-align:middle;
}

#ReportTable tbody td.editable span {
  border-bottom: 1px dashed #002e67
}

#ReportTable .actionHoveringDiv {
  transform: translateY(-12px);
}

#ReportTable   .periodTitle {
  text-transform: uppercase;
  font-size: 12px;
  color: #9faaae;
}

#ReportTable :focus {
  outline: unset
}

.status-scheduled {
  color: #009BC2;
  font-size: 10px
}

.status-sent {
  color: #96BF0D;
  font-size: 10px
}

.status-notSent {
  color: #d0021b;
  font-size: 10px
}