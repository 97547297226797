.row {
  margin-top: 20px;
}

.platformRow {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.platformLabel {
  display: flex;
  margin: 0px;
}

.platformToggle {
  margin-right: 8px;
  vertical-align: middle;
}