@import "client/colors";

.centeredItems {
  display: flex;
  align-items: center;

  label {
    margin: 0;
    font-weight: bold;
  }
}

.deleteButton {
  color: $errorRed !important;

  &:hover {
    background-color: $errorRed !important;
    color: white !important;
  }
}
.nameWrapper {
  position: relative;
  .warningIcon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    color: $dt-orange;
  }
}
