@import "../../../../colors";

.CmsCategoriesSection {
  position: relative;
  min-height: 100px;
  margin-top: 20px;

  :global {
    background-color: inherit;
  }
}

.sectionTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;

  :global {
    label {
      margin-bottom: 0;
    }
  }

  margin-bottom: 15px;
}

.categoryList {
  border: 1px solid $dt-border;
  border-radius: 5px;
  padding: 5px;

  :global {
    .hidden {
      display: none;
    }
  }
}

.categoryItem {
  display: flex;

  &>div {
    &:not(:first-child) {
      margin-left: 10px;
    }

    input {
      vertical-align: baseline;
      cursor: pointer;
    }
  }

  :global {
    .form-check-label {
      color: inherit !important;
      font-weight: normal !important;
      text-transform: none !important;
      font-size: 14px !important;
      display: inline;
      cursor: pointer;
    }

    .form-check-input {
      position: relative;
      margin: 0;
    }
  }
}

.expandColumn {
  font-weight: normal;
  color: $dt-button-grey;
  cursor: pointer;
}

.expandedCategory {
  border-top: 1px solid $dt-border;
  border-bottom: 1px solid $dt-border;
  margin: 5px 0;

  &:first-child {
    border-top: none;
  }

  &:last-child {
    border-bottom: none;
  }

  &+.expandedCategory {
    border-top: none;
  }
}

.searchContainer {
  margin-bottom: 10px;
}

.tagsContainer {
  display: flex;

  :global {
    .badge {
      margin-bottom: 10px;
      margin-right: 10px;
      padding: 6px 8px;
      background: transparent;
      font-size: 14px;
      font-weight: normal;
      // background-color: $dt-darkBlue;
      color: $dt-black;
      border: 1px solid $dt-button-grey;
      border-radius: 5px;

      .iconDelete {
        cursor: pointer;
        opacity: 0.5;

        &:hover {
          // color: $dt-violet;
          opacity: 1;
        }
      }
    }
  }
}