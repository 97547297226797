@import "client/colors";
@import "client/mixins";
@import "client/typography";

.outer {
  position: relative;
  background-color: white;

  &:hover {
    background-color: $dt-background;
  }

  &:hover {
    .actions {
      display: flex;
    }
  }
}

.inner {
  background-color: transparent;
  position: relative;
  display: flex;
  height: 48px;
  align-items: center;
  align-self: stretch;
  border: 0px;
  padding: 0px 16px;
  gap: 8px;
  border-bottom: 1px solid $dt-border;

  /* border-left-right */
  box-shadow: 1px 0px 0px 0px $dt-border inset, -1px 0px 0px 0px $dt-border inset;

  width: 100%;

  &.selectable {
    cursor: pointer;
  }

  &.header {
    background: $dt-header-background ;
    border-top: 1px solid $dt-border;

    .leftColumn {
      gap: 16px;
    }
  }
}

.headerLabel {
  color: $dt-grey-3;
  text-align: center;
  font-family: Roboto;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.showHideDiv {
  font-size: 12px;
  font-weight: 400;
  color: $dt-black;
}

.showHideLabel {
  font-weight: 700;
}

.showHideButton {
  color: $dt-darkBlue;
  background: transparent;
  border: 0px;
  padding: 0px;
  text-decoration: underline;
}

.selectedColumn {
  flex: 0 0 24px;
  padding-right: 8px;
  height: 100%;
  display: flex;
  align-items: center;
}

.leftColumn {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
}

.rightColumn {
  flex: 0 0 200px;
  display: flex;
  padding: 0px 16px;
  align-items: center;
  align-self: stretch;
  overflow: hidden;
}

.text {
  @include defaultFont;
  color: $dt-black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.actions {
  position: absolute;
  right: 2px;
  top: 2px;
  bottom: 2px;
  min-width: 180px;
  background-color: $dt-background;
  display: none;
  padding: 0px 24px;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}


.copyNotification {
  color: $dt-green;
}