@import 'client/colors';
@import 'client/mixins';

.NewsletterDetails {

  @include fullPageModal();

  :global {
    .btn-outline-secondary:disabled {
      cursor: not-allowed;
    }
  }

}

.content {
  position: relative;
}

.header {
  display: flex;
  align-items: center;

  >*:not(:first-child) {
    margin-left: 20px;
  }
}

.nameInput {
  height: 48px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.settingsContainer {
  padding: 0 20px;
}

.section {
  padding: 20px 0;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.sectionTitle {
  color: $dt-darkBlue;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.sectionContent {
  color: inherit;
  margin-top: 20px;
  font-size: 14px;

}

.flexRow {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.toggleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.inlineLabel {
  font-weight: bold;
  text-transform: capitalize;
}

.additionalToolbar {
  background-color: white;
  box-shadow: 0px -4px 8px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  gap: 24px;

}

.tagListContainer {
  margin: 20px 0;
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.tagListTag {
  border: 1px solid $dt-button-grey;
  border-radius: 4px;
  padding: 6px 8px;
  cursor: pointer;
  white-space: nowrap;

  img {
    margin-left: 5px;
    width: 14px;
  }
}

// NOTE: Uncomment below in case we want to apply custom styling for tags input
.tagsInputContainer {
  --bypassNonEmptyRule: true;
  // div[class*="multiValue"] {
  //   background-color: $dt-darkBlue;
  //   color: white;
  //   div:first-child{
  //     color: white;
  //   }
  // }
}

.sendButton {
  white-space: nowrap;
  background-color: $dt-green !important;
  color: white !important;

  &:hover {
    background-color: $dt-green !important;
    filter: brightness(90%);
  }
}