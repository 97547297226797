@import "client/colors";

.AccordionItem {
  background-color: $dt-background;
}

.AccordionItemHeader {
  padding: 16px;
  background-color: #ffffff;
  cursor: pointer;
  border-bottom: 1px solid $dt-border;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.AccordionItemHeaderTitle {
  text-transform: uppercase;
  color: $dt-darkBlue;
  font-weight: bold;
  font-size: 12px;
  flex: 1;
}

.AccordionItemHeaderIcon {
  color: $dt-darkBlue;
  font-weight: 400;
}

.AccordionItemBody {
  padding: 16px 0;
}
