@import "client/colors";

.ArticleDiscussionComment {
  padding: 16px 32px;
  border-top: 1px solid $dt-header-border;
  border-bottom: 1px solid transparent;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  gap: 40px;

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
    cursor: pointer;
  }
}

.hidden {
  display: none;
}

.highlighted {
  background-color: #ffffff;
}

.commentContainer {
  flex: 1;
}

.commentText {
  font-style: italic;
}

.commentInfo {
  display: flex;
  flex-direction: row;
}

.commentAuthor {
  font-weight: bold;
}

.commentDate {
  margin-left: 10px;
}

.ArticleDiscussionCommentReplies {
  margin-left: 32px;
  border-left: 1px solid $dt-header-border;

  div {
    border-top: 1px solid transparent;
  }
}

.clickable {
  cursor: pointer;
}

.activeWithReports {
  border: 1px solid $errorRed;
  border-width: 1px 0;
  border-top: 1px solid $errorRed !important;
  // border-bottom: 1px solid $errorRed;
  background-color: #ffffff;
}

.reportsContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-items: center;
  color: $dt-button-grey;
}

.attention {
  color: $errorRed;
}

.deletedComment {
  opacity: 0.5;
  .commentContainer {
    text-decoration: line-through;
  }
}

.updateDateText {
  margin-left: 8px;
  font-size: 14px;
  font-style: italic;
}
