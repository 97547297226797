@import "client/colors";
@import "client/mixins";
@import "client/typography";

.toolbar {
  padding: 10px;
  background-color: $dt-header-background;
  border: 1px solid $dt-header-border;
  margin-bottom: 20px;
  border-radius: 4px 4px 0px 0px;
}

.bucketSelectors {
  display: flex;
  gap: 20px;
}

.flex1 {
  flex: 1;
}

.flex2 {
  flex: 2;
}

.label{
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
}