@import 'client/colors';

.CmsDuplicateDialog {
  color: inherit;
  font-size: 14px;
  min-height: 100px;
}

.variationsList {
  margin-top: 20px;
  list-style-type: none;
  padding-inline-start: 4px;

  li {
    text-transform: capitalize;
    padding: 8px 0;
    cursor: pointer;

    &.disabled {
      opacity: 0.6;
      cursor: unset;
    }

    &:not(:first-child) {
      border-top: 1px solid $dt-border;
    }

    input {
      margin-right: 8px;
    }
  }
}