@import "client/colors";

.modal {
    :global {
        label {
            font-weight: bold;
        }

        // .row > div{
        //     display: flex;
        //     align-items: center;
        //     height: 100%;
        // }
    }

}

.fieldContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;

    input {
        width: 100%;
    }
}

.datePickerWrapper {
    position: relative;
    width: 100%;

    &::after {
        position: absolute;
        pointer-events: none;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: $dt-darkBlue;
        font-family: "FontAwesome";
        content: "\f073";
        height: 28px;
    }

    :global {
        .react-datepicker-wrapper {
            width: 100%;

            .react-datepicker__input-container {
                width: 100%;

                .form-control.is-invalid {
                    background-image: none;
                }
            }

            .react-datepicker__close-icon {
                right: 37px;
            }
        }

        .react-datepicker-popper {
            z-index: 2;
        }
    }
}

.advancedSelectWrapper {
    width: 100%;
}