@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#WidgetSelector {
  background: #ffffff;
  -webkit-box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;
  width: calc(100% - 140px);
  left: 140px;
  z-index: 3;
  left: 0px;
  width: calc(100%)
}

#WidgetSelector>.header {
  padding: 20px;
  border-bottom: 1px solid #E5E5E5;
  position: relative;
  -webkit-box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1)
}

#WidgetSelector>.header .closeIcon {
  cursor: pointer;
  z-index: 2
}

#WidgetSelector>.header .headerTitle {
  position: absolute;
  left: 25%;
  -webkit-transform: translateX(-25%);
  transform: translateX(-25%);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: #002e67;
  line-height: 48px
}

#WidgetSelector>.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  padding-bottom: 20px;
  overflow: hidden;
  height: calc(100% - 65px)
}

#WidgetSelector>.content.simple {
  overflow: auto;
  overflow: overlay;
  padding: 20px;
  height: calc(100% - 85px);
  background: white;
  position: relative
}

#WidgetSelector>.content.simple::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#WidgetSelector>.content.simple::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#WidgetSelector>.content.simple::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#WidgetSelector>.content.simple {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#WidgetSelector>.content.simple:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#WidgetSelector>.content.simple:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#WidgetSelector>.content.simple:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#WidgetSelector>.content.simple:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#WidgetSelector>.content .leftPart {
  position: relative;
  background: white;
  -webkit-box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1)
}

#WidgetSelector>.content .rightPart {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  background: #F6F6F6
}

#WidgetSelector>.content .rightPart .nonCollapsible {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: auto;
  overflow: overlay
}

#WidgetSelector>.content .rightPart .nonCollapsible::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#WidgetSelector>.content .rightPart .nonCollapsible::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#WidgetSelector>.content .rightPart .nonCollapsible::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#WidgetSelector>.content .rightPart .nonCollapsible {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#WidgetSelector>.content .rightPart .nonCollapsible:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#WidgetSelector>.content .rightPart .nonCollapsible:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#WidgetSelector>.content .rightPart .nonCollapsible:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#WidgetSelector>.content .rightPart .nonCollapsible:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#WidgetSelector>.content .rightPart .collapsible {
  background: white;
  position: relative;
  -webkit-box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  border-left: 1px solid #E5E5E5
}

#WidgetSelector .loaderContainer {
  position: relative;
  min-height: 285px;
  border: 1px solid #E5E5E5;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: none
}

#WidgetSelector .loaderWrapper {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center
}

#WidgetSelector>.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

#WidgetSelector>.header .headerTitle {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  position: unset;
  -webkit-transform: unset;
  transform: unset;
  left: unset
}

#WidgetSelector>.header .btn,
#WidgetSelector>.header input {
  margin-left: 10px
}

#WidgetSelector #pageDropDown {
  text-transform: unset
}