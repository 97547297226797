@import "client/colors";

.FeedSelectorModal {
  color: inherit;

  :global {
    .modal-body {
      max-height: calc(100vh - 220px);
      overflow-y: auto;
    }
  }
}