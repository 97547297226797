@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

.TranslationsComponent {
  color: inherit
}

.TranslationsComponent .translationBox {
  background-color: #EDF0F4;
  padding: 20px;
  margin-top: 20px;
  border-radius: 5px;
  border: 1px solid #E1E6ED;
  font-size: 14px
}

.TranslationsComponent .translationBox.primary {
  background-color: #F6F6F6;
  border: 1px solid #E5E5E5
}

.TranslationsComponent .translationBox .titleDiv {
  color: #002e67;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 10px
}

.TranslationsComponent .translationBox label {
  font-weight: bold
}

.TranslationsComponent .translationBox input[readonly] {
  background-color: #ffffff;
  color: #9faaae
}

.TranslationsComponent .translationBox input[readonly]:focus {
  background-color: #ffffff !important;
  color: #9faaae !important
}