@import "client/colors";
@import "client/mixins";

.NcSlidesManager {
  color: inherit;
  height: 100%;
  display: flex;

  :global {
    #ArticleSelector {
      position: fixed;
    }
  }
}

.NcSlidesOverviewListWrapper {
  flex: 1;
  border-right: 1px solid $dt-button-border;
  min-width: 0;
}

.NcSlidesOverviewList {
  margin-bottom: 4px;
}

.NcSlideDetails {
  flex: 3;
}

.sectionTitle {
  color: $dt-darkBlue;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 32px 16px;

  .hasErrorsIcon {
    margin-left: 8px;
    color: $errorRed;
    display: inline;
  }
}

.NcSlidesListItem {
  padding: 0 16px;
  margin: 16px 0;
}

.NcSlideTile {
  border: 1px solid $dt-button-border;
  border-radius: 4px;
  padding: 8px;
  display: flex;
  gap: 4px;
  align-items: center;

  .text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex: 1;
    margin-left: 4px;
  }

  .typeText {
    font-style: italic;
    text-transform: capitalize;
  }

  .deleteIcon {
    color: $errorRed;
    visibility: hidden;
  }

  &:hover {
    color: $dt-orange;
    cursor: pointer;

    .deleteIcon {
      visibility: visible;
    }

    .typeIcon {
      filter: brightness(0) saturate(100%) invert(53%) sepia(32%) saturate(2335%) hue-rotate(356deg) brightness(97%)
        contrast(91%); //dt-orange
    }
  }

  &.activeTile {
    border-color: $dt-orange;
    color: $dt-orange;

    .deleteIcon {
      visibility: visible;
    }

    .typeIcon {
      filter: brightness(0) saturate(100%) invert(53%) sepia(32%) saturate(2335%) hue-rotate(356deg) brightness(97%)
        contrast(91%); //dt-orange
    }
  }
}

.activeItem {
  // border-right: 1px solid $dt-orange;
  position: relative;

  &:before {
    position: absolute;
    right: 0px;
    height: 16px;
    border-right: 8px solid $dt-button-border;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    // color: white;
    content: "";
    top: 50%;
    transform: translateY(-50%);
  }

  &:after {
    position: absolute;
    right: -1px;
    height: 16px;
    border-right: 8px solid white;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    // color: white;
    content: "";
    top: 50%;
    transform: translateY(-50%);
  }
}

.isDragging {
  div {
    margin: 0;
  }

  .NcSlideTile {
    background-color: $dt-header-background !important;
  }
}

.NcSlideDetails {
  display: flex;
  flex-direction: column;
  padding: 24px 8px;
}

.NcSlideConfig {
  background-color: $dt-header-background;
  border: 1px solid $dt-header-border;
  border-radius: 4px;
  margin: 0 16px;
  padding: 16px;

  label {
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
  }
}

.flexRow {
  display: flex;
  gap: 16px;

  &:not(:first-child) {
    margin-top: 16px;
  }
}

.durationInput {
  color: inherit;
  width: 108px;
  position: relative;

  &::after {
    content: "sec";
    position: absolute;
    top: 50%;
    right: 32px;
    transform: translateY(-50%);
  }
}

.NcSlidePreviewSection {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.previewContainerWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.articleMaxAgeInput {
  color: inherit;
  width: 108px;
  position: relative;

  &::after {
    content: "days";
    position: absolute;
    top: 50%;
    right: 32px;
    transform: translateY(-50%);
  }
}

.articleListPreview {
  border: 1px solid $dt-button-border;
  font-size: 12px;

  & > div {
    padding: 8px 16px;

    &:not(:first-child) {
      border-top: 1px solid $dt-button-border;
    }
  }
}

.articleListPreviewHeader {
  text-transform: uppercase;
  color: $dt-button-grey;
  font-size: 10px;
  font-weight: bold;
}

.articleListPreviewRow {
  background-color: #ffffff;
  position: relative;

  i {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: 14px;
    cursor: pointer;
    color: $errorRed;
  }
}

.notShownArticle {
  opacity: 0.5;
  cursor: help;
  padding-left: 32px !important;
  position: relative;

  &::before {
    position: absolute;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    font-family: "FontAwesome";
    content: "\f071";
    color: $errorRed;
  }
}

.requiredValueMissing {
  & > label {
    padding-right: 20px;
    position: relative;

    &::after {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      font-family: "FontAwesome";
      content: "\f071";
      color: $errorRed;
    }
  }

  & > div,
  & > input {
    border: 1px solid $errorRed;
    border-radius: 4px;
  }
  :global {
    .jsonArea {
      border-width: 2px;
    }
  }
}

.typeIcon {
  height: 16px;
}

.hasErrorsIcon {
  color: $errorRed;
  font-size: 12px;
}

.readOnly {
  & > label {
    padding-right: 20px;
    position: relative;

    &::after {
      position: absolute;
      top: 50%;
      right: 0px;
      transform: translateY(-50%);
      font-family: "FontAwesome";
      content: "\f071";
      color: $dt-blue;
    }
  }
  :global {
    .jsonArea {
      opacity: 0.7;
    }
  }
}
