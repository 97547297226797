@import "client/colors";
@import "client/mixins";

.CmsAgendaItemMeta {
  background-color: $dt-header-background;
  border: 1px solid $dt-header-border;
  border-radius: 4px;
  margin: 20px 20px 0 20px;
  padding: 20px;
  font-size: 14px;

  label {
    font-weight: bold;
  }

  :global {
    .row:not(:first-child) {
      margin-top: 20px;
    }
  }
}

.displayTimeWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  height: 38px;

  label {
    font-weight: normal;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.startDateWrapper {
  display: flex;
  align-items: center;
  gap: 16px;
}

.allDayWrapper {
  display: flex;
  align-items: center;
  height: 38px;
}

.datepickerWrapper {
  position: relative;
  z-index: 3;

  &::after {
    position: absolute;
    pointer-events: none;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $dt-darkBlue;
    font-family: "FontAwesome";
    content: "\f073";
  }

  :global {
    .react-datepicker-wrapper {
      width: 100%;

      .react-datepicker__input-container {
        width: 100%;

        .form-control.is-invalid {
          background-image: none;
        }
      }

      .react-datepicker__close-icon {
        right: 37px;
      }

      .react-datepicker-time__input {
        min-width: 100px;
      }

      input {
        font-size: 14px;
        color: $dt-darkBlue;
      }
    }

    .react-datepicker-popper {
      z-index: 2;
    }
  }
}
