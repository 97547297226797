@import "../../../../colors";

.Categories {
  position: relative;
  min-height: 100px;
  margin-top: 20px;

  :global {
    .expand-cell {
      text-overflow: unset !important;
      padding-top: 0px;
      padding-bottom: 0px;
    }

    .hiddenHeader {
      // display: none;
      visibility: hidden;
      border: 0px;

      tr th {
        padding: 0;
      }
    }

    .table-hover tr:not(.nonExpandingRow):hover td {
      background-color: transparent;
    }

    .rowInExpanding:hover td {
      background-color: $dt-background !important;
    }

    .row-expansion-style {
      padding: 0;
    }

    .expandDataTable .table {
      margin-bottom: 0px;

      tbody {
        border: 0px !important;
      }

      tr {
        border: 0 0 1px 0 !important;

        &:last-child {
          border: 0px !important;
        }
      }
    }

    .actionHoveringDivInExpanding {
      display: none;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
    }

    tr.rowInExpanding:hover .actionHoveringDivInExpanding {
      display: block;
      background-color: $dt-background !important;
    }

    .movingRow {
      background-color: $dt-darkBlue;

      &:hover td {
        background-color: $dt-darkBlue !important;

        .actionHoveringDivInExpanding {
          background-color: $dt-darkBlue !important;
        }
      }

      td {
        color: white;
      }
    }

    .movingTarget {
      background-color: $dt-header-background;

      &:hover td {
        background-color: $dt-darkBlue !important;
        color: white;
      }
    }

  }
}