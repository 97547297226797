.widgetForm {
  :global {
    label {
      font-weight: bold;
    }

    .react-toggle {
      vertical-align: bottom;
      margin-right: 10px;
    }
  }
}