@import 'client/colors';

.advancedConfigurationForm {
  label {
    font-weight: bold;
  }
}

.verticalCenteredContent {
  display: flex;
  align-items: center;
}

.footer {
  display: flex;
  gap: 10px;
}