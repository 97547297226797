@import "../../../../../colors";
@import "../../../../../mixins";

.AudienceBuilder {
  padding: 20px;

  .builderTitle {
    color: $dt-darkBlue;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .operatorToolbar {
    display: flex;
    padding: 0 10px;
    margin-top: 20px;
  }

  .countBox {
    background: $dt-header-background;
    color: $dt-black;
    padding: 0 5px;
    line-height: 38px;
    display: flex;
    align-items: center;
    min-width: 50px;
    justify-content: center;

    img {
      margin-right: 5px;
    }

    &:last-of-type {
      margin-left: 10px;
    }
  }

  .elementBoxWrapper {
    display: flex;
    flex-direction: column;
  }

  .elementBoxConnector {
    margin-left: 30px;
    height: 30px;
    width: 20px;
    border-left: 1px solid $dt-header-border;
  }

  .elementBox {
    background: $dt-header-background;
    opacity: 1;
    border: 1px solid $dt-header-border;
    border-radius: 5px;
    padding: 20px;

    // min-height: 150px;
    :global {
      .react-datepicker-wrapper {
        width: 100%;
      }
    }
  }

  .flexRow {
    display: flex;

    &:not(:first-child) {
      margin-top: 20px;
    }
  }

  .conditionToolbar {
    display: flex;
    padding: 0 10px;
    align-items: center;
  }

  .operatorLabel {
    text-transform: uppercase;
    padding: 2px 10px 2px 10px;
    // border:1px solid red;
    text-align: center;
    color: $dt-button-grey;
  }

  .deleteCondition {
    margin-right: 5px;

    :global {
      .btn-outline-secondary {
        border-color: $dt-border !important;
        box-shadow: none !important;
      }
    }
  }

  .unsupportedQueryLabel {
    text-transform: uppercase;
    color: $dt-orange;
    font-weight: bold;
    display: flex;
    align-items: center;
    padding-right: 10px;
  }

  :global {
    *[disabled] {
      cursor: not-allowed;
    }
  }
}