@import "client/colors";
@import "client/mixins";
@import "client/typography";

.inputLabel {
  color: $dt-black;
  padding-right: 8px;
  @include boldFont;
}

.labelRow {
  display: flex;
  gap: 8px;
}

.opacity7 {
  opacity: 0.7;
}

.text {
  @include defaultFont;
}

.fileListPreview {
  li {
    padding: 4px 0;
  }

  li:not(:last-child) {
    border-bottom: 1px solid silver;
  }
}

.clickable {
  // text-decoration: underline;
  cursor: pointer;
  color: $dt-darkBlue;
}

.red{
  color: $errorRed;
}

.flexRowContainer {
  display: flex;
  align-items: center;
  gap: 8px;
}

.fileInputButton{
  color: transparent;
  width: 100%;
  
}

.fileInputText {
  position: absolute;
  top:5px;
  left: 150px;
  cursor: default;
  // font-style: italic;
}