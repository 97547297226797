@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#SurveySectionBuilder .sectionTitle {
  text-transform: uppercase;
  font-weight: bold;
  margin-bottom: 10px
}

#SurveySectionBuilder .elementBox {
  min-height: unset
}

#SurveySectionBuilder .questionBox .headerDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

#SurveySectionBuilder .questionBox .headerDiv .header {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

#SurveySectionBuilder .questionBox .headerDiv label {
  margin-bottom: 0
}

#SurveySectionBuilder .questionBox .headerDiv i {
  cursor: pointer
}

#SurveySectionBuilder .questionBox.withMargin {
  margin: 20px 0
}

#SurveySectionBuilder .addNewQuestionButtonDiv {
  margin: 10px 0
}

#SurveySectionBuilder .addNewQuestionButtonDiv .addNewQuestionButton>button {
  width: 100%
}

#SurveySectionBuilder .addNewQuestionButtonDiv .addNewQuestionButton .dropdown-menu {
  width: 100%
}

#SurveySectionBuilder .optionsDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

#SurveySectionBuilder .optionsDiv .spacer {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

#SurveySectionBuilder .optionsDiv label {
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 0
}

#SurveySectionBuilder .optionsDiv input[type="number"] {
  width: 100px
}

#SurveySectionBuilder .imagePreview {
  text-align: center
}

#SurveySectionBuilder .imagePreview img {
  max-height: 200px;
  margin: 20px auto
}