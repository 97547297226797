@import "client/colors";
@import "client/mixins";

.sharepointSiteDetails {
  margin-top: 20px;
}

.idContainer {
  padding: 0px 20px;
  // margin: 0 20px;
  border-width: 0 1px;
  border-style: solid;
  border-color: $dt-border;
}

.linkContainer {
  padding: 0;
}

.flexSpacer {
  flex: 1;
}

.headerLabel {
  color: $dt-grey-3;
  font-size: 12px;
  font-weight: bold;
}

.headerVal {
  color: $dt-darkBlue;
  font-size: 20px;
  font-weight: bold;

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.listsTableWrapper {
  margin: 40px 20px;

  :global {
    .react-bootstrap-table-pagination {
      background-color: transparent;
    }
    .hideOnHover {
      background-color: transparent !important;
    }
    .btn-custom {
      background-color: $errorRed !important;
      color: #fff !important;
      border-radius: 4px !important;
      border-color: $errorRed !important;
      box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
      font-size: 10px !important;
      font-weight: bold !important;
      text-transform: uppercase !important;
      letter-spacing: 0.5px !important;
      font-stretch: normal !important;
      font-style: normal !important;
      line-height: normal !important;
      height: 24px;
      border: 0;

      &:hover {
        color: #fff;
        filter: brightness(85%);
      }
    }
  }
}

.expiredText {
  color: $dt-orange;
  i {
    margin-right: 4px;
  }
}

.subscribedText {
  color: $dt-green;
  i {
    margin-right: 4px;
  }
}
