@import "client/colors";

.CmsConfirmationSection {
  color: inherit;

  .sectionTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
}