@import 'client/colors';

.Comments {
  min-height: 100px;
  margin-top: 20px;

  :global {
    .selection-cell-header {
      input[type="checkbox"] {
        width: 16px;
        height: 16px;
        margin: auto;
      }
    }
  }
}

.commentText {
  font-style: italic;
  text-overflow: ellipsis;
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 8px;

}

.articleTitle {
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
}

.articleAuthor {
  font-weight: normal;
  font-size: 12px;
  margin-left: 5px;
}

.filtersContainer {
  background-color: $dt-header-background;
  border: 1px solid $dt-header-border;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;

  label {
    font-weight: bold;
  }
}

.overviewRow {
  td {
    padding: 16px !important;
  }

  input[type="checkbox"] {
    width: 16px;
    height: 16px
  }
}


.deleteButtonWrapper {
  position: fixed;
  bottom: 50px;
  left: calc(50% + 120px);
  transform: translateX(calc(-50% - 60px));
  padding: 16px 24px;
  border: 1px solid $dt-button-grey;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  background: $dt-background;
}

.datePickerOffRange {
  color: $dt-orange;
  position: relative;

  &:hover::after {
    content: 'The selected period will be auto-adjusted to the max period range.';
    position: absolute;
    top: -20px;
    left: 0%;
    padding: 0px 10px;
    border-radius: 5px;
    border: 1px solid #aeaeae;
    width: auto;
    background-color: #F6F6F6;
    z-index: 8888;
    color: black;
    transform: translateY(-50%);
  }
}

.dateFiltersContainer {
  display: flex;
  gap: 20px;

  :global {
    .react-datepicker__input-container {
      position: relative;

      &::after {
        content: 'From:';
        position: absolute;
        top: 50%;
        width: auto;
        left: 10px;
        transform: translateY(-50%);
        font-size: 14px;
        color: $dt-button-grey;
      }

      input {
        padding-left: 50px;
        max-width: 160px;
      }
    }

  }

  >div:last-of-type {
    input {
      padding-left: 35px;
    }

    :global .react-datepicker__input-container::after {
      content: 'To:'
    }
  }

}

.datePickerPopper {
  left: 30px !important;
  top: 20px !important;
}

.maxPeriodRangeText {
  text-align: left;
  font-style: italic;
  color: $dt-orange;
  padding: 10px 5px 5px 5px;
}