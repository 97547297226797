@import "client/colors";

.CmsFeedSelector {
    height: 100%;
    background-color: $dt-header-background;
    border: 1px solid $dt-header-border;
    border-radius: 4px 4px 0 0;
    padding: 10px;
    margin-bottom: 20px;

    label {
        text-transform: uppercase;
        font-family: "Roboto";
        font-weight: bold;
        font-size: 12px;
        letter-spacing: 0.5px;
        margin: 0;
    }

    &:hover .editButton {
        opacity: 1;
    }
}

.flexRow {
    display: flex;
    align-items: center;
    gap: 5px;
}

.flexSeparator {
    flex: 1;
}

.createButton {
    margin-left: 10px;
}

.editButton {
    color: $dt-button-grey;
    opacity: 0.3;
    cursor: pointer;
}

.selectInputWrapper {
    position: relative;
    .warningIcon {
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        color: $dt-orange;
    }
}
