#HistoryTable .titleDiv {
  color: #002e67;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-left: 10px
}

#HistoryTable tbody tr:hover {
  cursor: pointer
}

.status-scheduled {
  color: #009BC2;
  font-size: 10px
}

.status-sent {
  color: #96BF0D;
  font-size: 10px
}

.status-notSent {
  color: #d0021b;
  font-size: 10px
}