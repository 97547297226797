@import "../../colors";

.ArticleLister {
  position: relative;

  :global {
    i.status {
      font-size: 10px;

      &.status-visible {
        color: $dt-green;
      }

      &.status-partial {
        color: $dt-blue;
      }

      &.status-hidden {
        color: #d0021b;
      }
    }

    .btn:focus,
    input:focus {
      box-shadow: none;
    }

    .react-bootstrap-table {
      border-radius: 4px 4px 0 0;

      thead {
        height: 50px;
      }
    }

    .react-bootstrap-table-pagination {
      background-color: transparent;
    }

    .loaderContainer {
      position: absolute !important;
    }

  }
}

.searchDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.searchInput {
  flex: 1;
  border-color: $dt-border !important;
}

.titleDiv {
  color: $dt-darkBlue;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
  flex: 1;
  margin-right: 20px;
}

.tableDiv {
  position: relative;
}