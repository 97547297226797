.AudiencesTargeting {
  background-color: inherit;
  // margin-top: 20px;
}

.infoContainer {
  display: flex;
  margin-bottom: 10px;
}

.title {
  font-weight: bold;
}