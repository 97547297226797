@import 'client/colors';

.NcSettings {
  display: flex;
  gap: 24px;
  justify-content: space-between;
}

.modalTitle {
  font-size: 20px;
  font-weight: bold;
  color: $dt-darkBlue;
  text-align: center;
  flex: 1;
}