@import 'client/colors';

.NcDevices {
  color: inherit;

  :global {
    i.status {
      font-size: 10px;

      &.status-online {
        color: $dt-green;
      }

      &.status-offline {
        color: $errorRed;

      }

      &.status-disconnected {
        color: $dt-button-grey;
      }
    }
  }
}

.disconnectLink {
  color: $errorRed;
  cursor: pointer;
  text-decoration: underline;
}