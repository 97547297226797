@import "client/colors";

.ChannelInfoPairs {
  margin-top: 10px;

  :global {
    #MediaSelector {
      position: fixed;
      left: 140px;
      width: calc(100% - 140px);
    }
  }
}

.fieldRow {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.fieldRowTypeContainer {
  flex: 1;
}

.fieldRowValueContainer {
  flex: 4;
  display: flex;
  gap: 10px;
}