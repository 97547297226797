@import 'client/colors';
@import 'client/mixins';

.NewsletterTemplatesetDetails {

  @include fullPageModal();

}

.header {
  display: flex;
  align-items: center;

  >*:not(:first-child) {
    margin-left: 20px;
  }
}

.nameInput {
  height: 48px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.settingsContainer {
  padding: 0 20px;
}

.section {
  padding: 20px 0;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.sectionTitle {
  color: $dt-darkBlue;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.sectionContent {
  color: inherit;
  margin-top: 20px;
  font-size: 14px;

}

.flexRow {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.toggleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.inlineLabel {
  font-weight: bold;
  text-transform: capitalize;
}