.Charts_topLevelContainer .loaderWrapper {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center
}

.Charts_topLevelContainer .chart-body {
  height: 300px
}