#Monitoring {
  padding-top: 20px;
  color: #444
}

#Monitoring .btn {
  text-transform: uppercase
}

#Monitoring .nav-tabs {
  border: none
}

#Monitoring .nav-tabs .nav-item {
  margin-right: 10px
}

#Monitoring .nav-tabs .nav-item .nav-link {
  border: 1px solid #002e67 !important;
  color: #002e67 !important
}

#Monitoring .nav-tabs .nav-item .nav-link:hover:not(.active) {
  border-color: #ee7d11 !important
}

#Monitoring .nav-tabs .nav-item .nav-link.active {
  border-color: #ee7d11 !important;
  background-color: #ee7d11 !important;
  color: #fff !important
}

#Monitoring .nav-tabs .nav-item .nav-link.active::after {
  content: none
}

#Monitoring .tab-content .tab-pane {
  padding-top: 20px
}