@import "client/colors";
@import "client/mixins";

.ReportedContentDetails {
  color: inherit;
}

.idContainer {
  padding: 0px 20px;
  // margin: 0 20px;
  border-width: 0 1px;
  border-style: solid;
  border-color: $dt-border;
}

.linkContainer {
  padding: 0;
}

.flexSpacer {
  flex: 1;
}

.qrContainer {
  padding: 0 20px;
  display: flex;
  align-items: center;

  :global {
    .qrToggler {
      cursor: pointer;
      font-size: 32px;
      color: $dt-darkBlue;
    }
  }
}

.headerLabel {
  color: $dt-grey-3;
  font-size: 12px;
  font-weight: bold;
}

.headerVal {
  color: $dt-darkBlue;
  font-size: 20px;
  font-weight: bold;

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.articlePreviewWrapper {
  @include edScrollbar;
  padding: 20px;
  height: 100%;
}

.articlePreviewContainer {
  :global {
    #ArticleContent {
      border: 1px solid $dt-border;
    }
  }
}

.accordionHeader {
  padding: 16px;
  background-color: #ffffff;
  cursor: pointer;
  border-bottom: 1px solid $dt-border;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
}

.accordionLabel {
  text-transform: uppercase;
  color: $dt-darkBlue;
  font-weight: bold;
  font-size: 12px;
  flex: 1;
}

.accordionIcon {
  color: $dt-darkBlue;
  font-weight: 400;
}

.sectionHelpText {
  font-style: italic;
  color: $dt-button-grey;
  font-size: 14px;
  padding: 0 16px;
}

.articleDiscussionWrapper {
  flex: 1;
  height: 100%;
}

.reportsIncCommentsWrapper {
  padding: 16px;
}

.reportsInPostWrapper {
  padding: 16px;
}

.attentionBorder {
  :global {
    #ArticleContent {
      border: 1px solid $errorRed;
    }
  }
}

.postReportsToolbar {
  margin-top: 16px;
  display: flex;
  gap: 16px;
}

.postReportsIconWrapper {
  display: flex;
  gap: 4px;
  align-items: center;
  color: $dt-button-grey;
}

.attention {
  color: $errorRed;
}

.articleVisibleToggleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    font-weight: bold;
  }
}

.articleDiscussionHelpTextWrapper {
  display: flex;
  justify-content: space-between;
}

.showOnlyReportedWrapper {
  color: $dt-black;
  display: flex;
  gap: 5px;
  font-style: normal;
}
