.People {
    position: relative;
    min-height: 100px;
    margin-top: 20px;
}

:global {
    .alert.analysis {
        width: 100%;

        hr {
            margin: 0 0 5px 0;
        }

        .sublist li {
            margin: 10px 0;
        }

        .extraInfo {
            font-size: 12px;
            margin-top: 5px;

            .material-icons {
                font-size: 16px;
                vertical-align: text-bottom;
            }
        }
    }
}