@import "client/colors";

.EstimatedSizeIcon {
  position: absolute;
  right: 0px;
  top: 5px;
  transform: translate(0px, -5px);
  padding: 0 5px;
  background: $dt-header-background;
  color: $dt-darkBlue;
  cursor: pointer;
}