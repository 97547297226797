@import 'client/colors';

.NcSettingsGallery {
  padding: 24px;
  overflow-y: auto;

  :global {
    #MediaSelector {
      position: fixed;
    }
  }
}

.guidelinesWrapper {
  margin-bottom: 24px;
  font-size: 14px;
}

.mediaItemsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}