#ChannelSelector {
  margin-top: 20px
}

#ChannelSelector .titleDiv {
  color: #002e67;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold
}

#ChannelSelector :focus {
  outline: unset
}

#ChannelSelector .table tr[width]:hover {
  background-color: unset
}

#ChannelSelector .table tr[width] tr.leaf>td:first-child {
  background-image: url("./child_arrow.svg");
  background-repeat: no-repeat;
  background-position-y: calc(50% - 4px);
  text-overflow: unset;
  padding-left: 20px
}

#ChannelSelector .table tr[width]>td {
  position: relative
}

#ChannelSelector .table td[colspan] {
  padding: 0;
  padding-left: 20px
}

#ChannelSelector .table td[colspan] .table {
  border: 0px
}

#ChannelSelector .table td[colspan] .react-bs-container-body {
  border: 0px
}

#ChannelSelector .table thead .react-bs-select-all {
  visibility: hidden
}

#ChannelSelector .unselectable {
  color: #9faaae
}

#ChannelSelector .highlighted {
  font-weight: bold;
  color: inherit
}

#ChannelSelector .topLevel {
  font-weight: bold;
  font-style: italic;
  color: inherit
}

#ChannelSelector .faded {
  opacity: 0.2
}