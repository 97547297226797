@import "client/colors";
@import "client/mixins";

.FullPageModal {
  background: #ffffff;
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;
  width: calc(100% - 140px);
  left: 140px;
  z-index: 3;
}

.FullPageModalHeader {
  padding: 20px;
  border-bottom: 1px solid $dt-border;
  position: relative;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;

  >*:not(:first-child) {
    margin-left: 20px;
  }
}

.FullPageModalContent {
  position: relative;
  display: flex;
  height: calc(100% - 90px);
  overflow-y: auto;
}

.FullPageModalContentPart {
  @include edScrollbar;
  overflow-y: auto;
}

.mainPart {
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 2
}

.withBackground {
  background: $dt-background;
}

// ─────────────────────────────────────────────────────────────────────────────

.FullPageModalSettings {
  padding: 0 20px;
}

.FullPageModalSettingsSection {
  padding: 20px 0;
}

.FullPageModalSettingsSectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.FullPageModalSettingsSectionTitle {
  color: $dt-darkBlue;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.FullPageModalSettingsSectionContent {
  margin-top: 20px;
  font-size: 14px;
}

.FullPageModalSettingsSectionRow {
  // display: flex;
  // align-items: center;
  // gap: 15px;
  margin-bottom: 15px;

  .label {
    // flex: 1;
    font-weight: bold;
    text-transform: capitalize;
    margin-bottom: 5px;
  }
}