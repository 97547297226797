@import 'client/colors';

.NcSlideshowPlan {
  color: inherit;
}

.NcSlideshowPlanToolbar {
  display: flex;
  align-items: center;
  gap: 16px;
}

.toolbarButtonLink {
  text-decoration: underline;
  color: $dt-darkBlue;
  cursor: pointer;
  font-size: 14px;
}

.planInfoWrapper {
  display: flex;

  &>div {
    flex: 1;

    label {
      font-weight: bold;
    }
  }
}

.timezoneContainer {
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  letter-spacing: 1px;
}