@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#ArticleQuickView {
  padding: 0
}

#ArticleQuickView .modal-title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

#ArticleQuickView .modal-title .headerTitle {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #002e67;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold
}

#ArticleQuickView .modal-body #ArticleContent {
  max-height: 600px;
  overflow: auto;
  overflow: overlay;
  background-color: white
}

#ArticleQuickView .modal-body #ArticleContent::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#ArticleQuickView .modal-body #ArticleContent::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#ArticleQuickView .modal-body #ArticleContent::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#ArticleQuickView .modal-body #ArticleContent {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#ArticleQuickView .modal-body #ArticleContent:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#ArticleQuickView .modal-body #ArticleContent:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#ArticleQuickView .modal-body #ArticleContent:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#ArticleQuickView .modal-body #ArticleContent:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#ArticleQuickView .loaderContainer {
  position: relative;
  min-height: 285px;
  border: 1px solid #E5E5E5;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: none
}

#ArticleQuickView .loaderWrapper {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center
}