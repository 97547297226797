#Overview>.row {
  margin-top: 40px
}

#Overview .placeholder {
  min-height: 400px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #E5E5E5
}

#Overview .boxContainer {
  border-radius: 4px;
  background-color: white;
  border: 1px solid #E5E5E5;
  height: 450px
}

#Overview .tableWithTitleBox .titleDiv {
  color: #002e67;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin-bottom: 20px;
  padding-left: 10px
}

#Overview .tableWithTitleBox .tableAboveDiv {
  text-align: right;
  position: absolute;
  right: 15px;
  top: 0
}

#Overview .loaderContainer {
  position: relative;
  min-height: 285px;
  border: 1px solid #E5E5E5;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: none
}

#Overview .loaderWrapper {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center
}

@media screen and (min-color-index: 0) and (-webkit-min-device-pixel-ratio: 0) {
  #TabbedChartsBox .chartBox .Charts_topLevelContainer {
    -webkit-transform: translate(calc(-50% - 0.5px), -50%) !important;
    transform: translate(calc(-50% - 0.5px), -50%) !important
  }

  #TabbedChartsBox .tabBox:nth-child(1) .StatBox {
    -webkit-transform: translate(calc(-50% - 0.5px), calc(-50% - 0.5px)) !important;
    transform: translate(calc(-50% - 0.5px), calc(-50% - 0.5px)) !important
  }

  #TabbedChartsBox .tabBox:nth-child(4) .StatBox {
    -webkit-transform: translate(calc(-50%), calc(-50% - 0.5px)) !important;
    transform: translate(calc(-50%), calc(-50% - 0.5px)) !important
  }
}