@import 'client/colors';

.nameInput {
  height: 48px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.inlineToggle {
  white-space: nowrap;
  margin-bottom: 0px;
  display: flex;
  gap: 8px;
  font-weight: bold;
  cursor: pointer;
}

.label {
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.targetingBox {
  border: 1px solid $dt-border;
  border-radius: 5px;
  min-height: 100px;
  padding: 5px;
  position: relative;
}

.targetingBoxRow {
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
    cursor: pointer;
  }

  input {
    cursor: pointer;
    margin-right: 5px;
  }

}

.alertSlideConfig {
  background-color: $dt-header-background;
  border: 1px solid $dt-header-border;
  border-radius: 4px;
  margin: 24px;
  padding: 16px;

  label {
    font-weight: bold;
    font-size: 14px;
    white-space: nowrap;
  }
}

.themeRadioWrapper {
  display: flex;
  gap: 16px;

  input,
  label {
    cursor: pointer;
  }

  label {
    font-weight: normal;
  }
}

.sectionTitle {
  color: $dt-darkBlue;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 24px;

}

.previewSection {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.previewContainerWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.previewContainer {
  border: 8px solid #000000;
  border-radius: 8px;
  width: 560px;
  height: 315px;
  display: flex;
  // justify-content: center;
  // align-items: center;
  position: relative;
  overflow: hidden;
}

.themeImageContainer {
  flex: 1;

  background-image: url('../../../previewAssets/preview-alert-emergency.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &[data-type="mourn"] {
    background-image: url('../../../previewAssets/preview-alert-mourn.png');
  }

  &[data-type="event"] {
    background-image: url('../../../previewAssets/preview-alert-event.png');
  }

  &[data-type="notification"] {
    background-image: url('../../../previewAssets/preview-alert-notification.png');
  }
}

.messageContainer {
  flex: 1;
  background-color: #3F5058;
  color: #ffffff;
  font-size: 18px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.messagePlaceholder {
  text-align: center;
  padding: 32px;
  text-align: center;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
}