#ReportScheduler .content {
  background-color: #EDF0F4;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #E1E6ED
}

#ReportScheduler label {
  font-weight: bold;
  white-space: nowrap
}

#ReportScheduler .buttonContainer {
  margin-top: 20px
}