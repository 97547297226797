#FormsOverviewTable {
  padding-top: 20px
}

#FormsOverviewTable :focus {
  outline: unset
}

#FormsOverviewTable .table tr[width]:hover {
  background-color: unset
}

#FormsOverviewTable .table td[colspan] {
  padding: 0;
  padding-left: 20px
}

#FormsOverviewTable .table td[colspan] .table {
  border: 0px
}

#FormsOverviewTable .table tr>td .react-toggle {
  vertical-align: middle
}

#FormsOverviewTable .table tr:hover>td .react-toggle {
  display: inline-block
}

#FormsOverviewTable .status-draft {
  color: #9faaae;
  font-size: 10px
}

#FormsOverviewTable .status-published {
  color: #96BF0D;
  font-size: 10px
}

#FormsOverviewTable .status-finished {
  color: #009BC2;
  font-size: 10px
}

#FormsOverviewTable .status-deleted {
  color: #d0021b;
  font-size: 10px
}

#FormsOverviewTable #OrtecLoader {
  z-index: unset
}