#TopNumbersChart {
  margin-top: 20px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

#TopNumbersChart .StatBox {
  text-align: center;
  font-weight: bold;
  color: #444;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 180px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0 5px;
  position: relative
}

#TopNumbersChart .StatBox:not(:first-child) {
  border-left: 1px solid #E5E5E5
}

#TopNumbersChart .StatBox .valueDiv {
  font-size: 48px;
  letter-spacing: 0.7px;
  line-height: 72px
}

#TopNumbersChart .StatBox .valueDiv .scaletext-wrapper {
  overflow: visible !important
}

#TopNumbersChart .StatBox .textDiv {
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase;
  height: 15px
}

#TopNumbersChart .StatBox .infoDiv {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #009BC2
}

#TopNumbersChart .StatBox .infoDiv i {
  font-size: 18px
}