@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#FormGenericConfig {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%
}

#FormGenericConfig .section {
  padding: 20px 0;
  border-bottom: 1px solid #E5E5E5
}

#FormGenericConfig .section:last-child {
  border-bottom: none
}

#FormGenericConfig .section .header {
  color: #002e67;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between
}

#FormGenericConfig .verticalCentered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

#FormGenericConfig .positionRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

#FormGenericConfig .positionRow label {
  margin-bottom: 0
}

#FormGenericConfig .positionRow>*:not(:last-child) {
  margin-right: 5px
}

#FormGenericConfig .positionElement {
  margin-top: 20px;
  padding-bottom: 10px
}

#FormGenericConfig .positionElement:not(:last-child) {
  border-bottom: 1px solid #E5E5E5
}

#FormGenericConfig .positionElement .positionRow {
  margin: 10px 0;
  font-size: 0.9em
}

#FormGenericConfig .positionElement .positionRow input[type="number"] {
  width: 40px;
  padding-left: 5px;
  padding-right: 2px
}

#FormGenericConfig .positionElement .positionRow input,
#FormGenericConfig .positionElement .positionRow select {
  font-size: 0.9em
}

#FormGenericConfig .removeFromChannelDiv {
  text-align: right;
  margin-bottom: 10px
}