@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#FormResults {
  background: #ffffff;
  -webkit-box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;
  width: calc(100% - 140px);
  left: 140px;
  z-index: 3
}

#FormResults>.header {
  padding: 20px;
  border-bottom: 1px solid #E5E5E5;
  position: relative;
  -webkit-box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1)
}

#FormResults>.header .closeIcon {
  cursor: pointer;
  z-index: 2
}

#FormResults>.header .headerTitle {
  position: absolute;
  left: 25%;
  -webkit-transform: translateX(-25%);
  transform: translateX(-25%);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: #002e67;
  line-height: 48px
}

#FormResults>.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  padding-bottom: 20px;
  overflow: hidden;
  height: calc(100% - 65px)
}

#FormResults>.content.simple {
  overflow: auto;
  overflow: overlay;
  padding: 20px;
  height: calc(100% - 85px);
  background: white;
  position: relative
}

#FormResults>.content.simple::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#FormResults>.content.simple::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#FormResults>.content.simple::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#FormResults>.content.simple {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#FormResults>.content.simple:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#FormResults>.content.simple:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#FormResults>.content.simple:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#FormResults>.content.simple:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#FormResults>.content .leftPart {
  position: relative;
  background: white;
  -webkit-box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1)
}

#FormResults>.content .rightPart {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  background: #F6F6F6
}

#FormResults>.content .rightPart .nonCollapsible {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: auto;
  overflow: overlay
}

#FormResults>.content .rightPart .nonCollapsible::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#FormResults>.content .rightPart .nonCollapsible::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#FormResults>.content .rightPart .nonCollapsible::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#FormResults>.content .rightPart .nonCollapsible {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#FormResults>.content .rightPart .nonCollapsible:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#FormResults>.content .rightPart .nonCollapsible:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#FormResults>.content .rightPart .nonCollapsible:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#FormResults>.content .rightPart .nonCollapsible:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#FormResults>.content .rightPart .collapsible {
  background: white;
  position: relative;
  -webkit-box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  border-left: 1px solid #E5E5E5
}

#FormResults .loaderContainer {
  position: relative;
  min-height: 285px;
  border: 1px solid #E5E5E5;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: none
}

#FormResults .loaderWrapper {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center
}

#FormResults>.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

#FormResults>.header .headerTitle {
  -webkit-transform: unset;
  transform: unset;
  position: unset;
  left: unset;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis
}

#FormResults>.content {
  height: calc(100vh - 90px);
  padding: 0px;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  display: -ms-flexbox;
  -ms-flex-direction: column
}

#FormResults>.content .contentRow {
  -webkit-box-flex: 1;
  flex: 1 0 0;
  -ms-flex: 1;
  padding: 20px;
  overflow: auto
}

#FormResults>.content .contentRow:first-child {
  border-bottom: 1px solid #E5E5E5;
  position: relative
}

#FormResults .notFoundContainer {
  text-align: center;
  margin-top: 20px
}

#FormResults .iconButton {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  background-color: #ffffff;
  color: #002e67
}

#FormResults .iconButton:focus {
  -webkit-box-shadow: none;
  box-shadow: none
}

#FormResults .iconButton.showAdvanced {
  background-color: #ee7d11;
  color: #ffffff
}

#FormResults .iconButton i.material-icons {
  vertical-align: middle
}

#FormResults .verticalCentered {
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end
}

#FormResults .verticalCentered .react-toggle {
  vertical-align: middle
}

#FormResults .verticalCentered .material-icons {
  vertical-align: middle
}

#FormResults .alert {
  margin-bottom: 0;
  padding: 0 1.25rem;
  height: 38px;
  line-height: 38px
}

#FormResults .btn {
  text-transform: uppercase
}

#quizSuccessesMessage {
  position: absolute;
  top: 5px;
  color: #96BF0D
}

#FormResultsDetailed {
  height: 100%
}

#FormResultsDetailed .tableDiv {
  height: 100%
}

#FormResultsDetailed .tableDiv table {
  height: 100%
}

#FormResultsDetailed .tableDiv table thead tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  counter-reset: col-num;
  overflow-y: scroll
}

#FormResultsDetailed .tableDiv table thead tr th {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  font-weight: normal;
  position: relative;
  padding-left: 10px;
  border: 1px solid #E5E5E5
}

#FormResultsDetailed .tableDiv table thead tr th:first-child {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 50px
}

#FormResultsDetailed .tableDiv table thead tr th.sectionStart {
  border-left: 1px solid #9faaae;
  counter-increment: col-num
}

#FormResultsDetailed .tableDiv table thead tr th.sectionStart::before {
  position: absolute;
  top: -20px;
  left: 5px;
  font-size: 12px;
  color: #9faaae;
  content: "Section " counter(col-num)
}

#FormResultsDetailed .tableDiv table thead i.typeIcon {
  vertical-align: middle;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'FontAwesome';
  content: "";
  font-weight: normal;
  font-size: 14px;
  color: #9faaae
}

#FormResultsDetailed .tableDiv table thead i.typeIcon[data-type="2"]::after {
  content: "\f00b"
}

#FormResultsDetailed .tableDiv table thead i.typeIcon[data-type="3"]::after {
  content: "\f0cb"
}

#FormResultsDetailed .tableDiv table thead i.typeIcon[data-type="4"]::after {
  content: "\f035"
}

#FormResultsDetailed .tableDiv table thead i.typeIcon[data-type="4m"]::after {
  content: "\f034"
}

#FormResultsDetailed .tableDiv table thead i.typeIcon[data-type="5"]::after {
  content: "\f005"
}

#FormResultsDetailed .tableDiv table tbody {
  display: block;
  overflow-y: scroll;
  width: 100%;
  height: 100%
}

#FormResultsDetailed .tableDiv table tbody tr {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-height: 50px
}

#FormResultsDetailed .tableDiv table tbody tr td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  overflow: hidden;
  border: 1px solid #E5E5E5
}

#FormResultsDetailed .tableDiv table tbody tr td .freeText {
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  height: 100%;
  display: block
}

#FormResultsDetailed .tableDiv table tbody tr td.userCell {
  -webkit-box-flex: 0;
  -ms-flex: none;
  flex: none;
  width: 50px;
  text-align: center
}

#FormResultsDetailed .tableDiv table tbody tr td.userCell i {
  visibility: hidden
}

#FormResultsDetailed .tableDiv table tbody tr td[colspan] {
  text-align: center;
  border-bottom: 0px
}

#FormResultsDetailed .tableDiv table tbody tr td span.correct::before {
  content: "\f058";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'FontAwesome';
  font-weight: normal;
  font-size: 14px;
  color: #96BF0D;
  margin-right: 5px
}

#FormResultsDetailed .tableDiv table tbody tr:hover td.userCell i {
  visibility: visible
}

#FormResultsOverall {
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  overflow: auto;
  overflow: overlay;
  height: 100%;
  padding-bottom: 20px;
  counter-reset: section-num
}

#FormResultsOverall::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#FormResultsOverall::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#FormResultsOverall::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#FormResultsOverall {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#FormResultsOverall:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#FormResultsOverall:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#FormResultsOverall:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#FormResultsOverall:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#FormResultsOverall.poll .questionCard {
  width: 80%;
  min-width: 450px;
  margin: 0 auto
}

#FormResultsOverall .questionCard {
  width: 450px;
  border-radius: 5px;
  border: 1px solid #E5E5E5;
  -webkit-box-flex: 0;
  flex: none;
  -ms-flex: none;
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -ms-flex-direction: column;
  margin-right: 20px;
  position: relative;
  margin-top: 20px
}

#FormResultsOverall .questionCard.sectionStart {
  counter-increment: section-num
}

#FormResultsOverall .questionCard.sectionStart:not(:first-child) {
  margin-left: 20px
}

#FormResultsOverall .questionCard.sectionStart::before {
  content: "Section " counter(section-num);
  position: absolute;
  top: -25px;
  left: 0px;
  font-size: 14px;
  color: #002e67
}

#FormResultsOverall .questionCard .questionCard_header {
  display: -webkit-box;
  display: flex;
  display: -ms-flexbox;
  height: 100px;
  border-bottom: 1px solid #E5E5E5;
  -webkit-box-flex: 0;
  flex: none;
  -ms-flex: none;
  position: relative
}

#FormResultsOverall .questionCard .questionCard_header::after {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'FontAwesome';
  content: "";
  position: absolute;
  top: 5px;
  right: 10px;
  font-weight: normal;
  font-size: 14px;
  color: #002e67
}

#FormResultsOverall .questionCard .questionCard_header[data-type="2"]::after {
  content: "\f00b"
}

#FormResultsOverall .questionCard .questionCard_header[data-type="3"]::after {
  content: "\f0cb"
}

#FormResultsOverall .questionCard .questionCard_header[data-type="4"]::after {
  content: "\f035"
}

#FormResultsOverall .questionCard .questionCard_header[data-type="4m"]::after {
  content: "\f034"
}

#FormResultsOverall .questionCard .questionCard_header[data-type="5"]::after {
  content: "\f005"
}

#FormResultsOverall .questionCard .questionCard_header .squareTile {
  padding: 20px;
  text-align: center;
  background: #002e67;
  color: white;
  font-weight: bold;
  border-radius: 5px 0 0 0;
  width: 100px
}

#FormResultsOverall .questionCard .questionCard_header .squareTile .questionPrefix {
  text-transform: uppercase;
  font-size: 12px
}

#FormResultsOverall .questionCard .questionCard_header .squareTile .questionNumber {
  font-size: 30px
}

#FormResultsOverall .questionCard .questionCard_header .questionText {
  -webkit-box-flex: 1;
  flex: 1 0 0;
  -ms-flex: 1;
  padding: 20px;
  font-weight: bold
}

#FormResultsOverall .questionCard .questionCard_body {
  overflow: auto;
  overflow: overlay;
  -webkit-box-flex: 1;
  flex: 1 0 0;
  -ms-flex: 1
}

#FormResultsOverall .questionCard .questionCard_body::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#FormResultsOverall .questionCard .questionCard_body::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#FormResultsOverall .questionCard .questionCard_body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#FormResultsOverall .questionCard .questionCard_body {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#FormResultsOverall .questionCard .questionCard_body:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#FormResultsOverall .questionCard .questionCard_body:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#FormResultsOverall .questionCard .questionCard_body:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#FormResultsOverall .questionCard .questionCard_body:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#FormResultsOverall .questionCard .questionCard_body table {
  width: 100%;
  table-layout: fixed;
  min-height: 100%
}

#FormResultsOverall .questionCard .questionCard_body table td {
  border: 1px solid #E5E5E5;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 5px
}

#FormResultsOverall .questionCard .questionCard_body table td:first-child {
  width: 50px;
  text-align: center
}

#FormResultsOverall .questionCard .questionCard_body table td i {
  vertical-align: text-bottom
}

#FormResultsOverall .questionCard .questionCard_body table td .progress-bar {
  background-color: #002e67
}

#FormResultsOverall .questionCard .questionCard_body table td.correct {
  position: relative
}

#FormResultsOverall .questionCard .questionCard_body table td.correct::after {
  content: "\f058";
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-family: 'FontAwesome';
  position: absolute;
  top: 5px;
  right: 10px;
  font-weight: normal;
  font-size: 14px;
  color: #96BF0D
}

#FormResultsOverall .questionCard .questionCard_body table.tableResults_star td {
  text-align: center
}

#FormResultsOverall .questionCard .questionCard_body table.tableResults_star td:first-child {
  width: 60px;
  text-align: right
}

#FormResultsOverall .questionCard .questionCard_body table.tableResults_star .naRow td:first-child,
#FormResultsOverall .questionCard .questionCard_body table.tableResults_star .totalRow td:first-child {
  text-align: center
}

#FormResultsOverall .questionCard .questionCard_body table.tableResults_star .totalRow {
  background-color: #E5E5E5
}

#FormResultsOverall .questionCard .questionCard_body table.tableResults_star .totalRow .avgStarsSpan {
  margin: 0 10px
}

#FormResultsOverall .questionCard .questionCard_body table.tableResults_star .totalRow .avgValueSpan {
  color: #002e67;
  font-size: 18px;
  font-weight: bold
}

#FormResultsOverall .questionCard .questionCard_body table.tableResults_singleAnswer td:first-child {
  width: auto
}

#FormResultsOverall .questionCard .questionCard_body table.tableResults_freeText td:first-child i {
  visibility: hidden
}

#FormResultsOverall .questionCard .questionCard_body table.tableResults_freeText tr:hover td:first-child i {
  visibility: visible
}

@media screen and (-ms-high-contrast: active),
screen and (-ms-high-contrast: none) {
  .questionCard_body table tr td {
    height: 100%
  }

  #FormResultsDetailed table {
    table-layout: fixed !important;
    height: auto !important;
    max-height: 100%
  }

  #FormResultsDetailed table thead tr {
    display: table-row !important
  }

  #FormResultsDetailed table tbody {
    display: table-row-group !important
  }

  #FormResultsDetailed table tbody tr {
    display: table-row !important
  }

  #FormResultsDetailed table tbody tr td .freeText {
    white-space: nowrap
  }

  #FormResults>.header .headerTitle {
    -webkit-transform: none !important;
    transform: none !important
  }
}

@-moz-document url-prefix() {
  #FormResultsDetailed table {
    display: flex;
    flex-direction: column
  }

  #FormResultsOverall .questionCard_body table {
    height: 100%
  }
}