@import "client/colors";
@import "client/mixins";

.pageSwitchWrapper {
  display: flex;
  align-items: center;
}

.pageSwitchLabel {
  flex: 1;

  label {
    line-height: 30px;
    margin: 0;
  }
}

.pageSwitchContainer {
  display: inline-block;
}

.pageSwitch {
  font-size: 10px;
  font-weight: bold;
  cursor: pointer;
  display: flex;
  border-radius: 4px;
  border: 1px solid $dt-button-grey;
  background-color: $dt-button-grey;
  color: #ffffff;
}

.pageSwitchOption {
  padding: 5px 15px;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  &.inline {
    padding: 0 15px;
  }

  &.active {
    background-color: #ffffff;
    color: $dt-button-grey;
    border-radius: 4px;
    border: 1px solid #ffffff;
  }
}