@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#FormBuilder {
  height: 100%
}

#FormBuilder .sectionListContainer {
  border-left: 1px solid #E5E5E5
}

#FormBuilder .titleDiv {
  color: #002e67;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  margin: 20px 0
}

#FormBuilder .mainDiv {
  background-color: #EDF0F4;
  border: 1px solid #E1E6ED;
  padding: 20px;
  border-radius: 5px
}

#FormBuilder .elementBox {
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #9faaae;
  border-radius: 5px;
  min-height: 300px
}

#FormBuilder .elementBox .header {
  text-transform: uppercase;
  font-weight: bold
}

#FormBuilder .draggableInput {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

#FormBuilder .draggableInput i {
  cursor: pointer
}

#FormBuilder .qrToggler {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 32px;
  cursor: pointer
}

#FormBuilder .qrToggler.disabled {
  opacity: 0.4
}