@import "../../../../colors";
@import "../../../../mixins";

.SourceDetails {
  @include fullPageModal();
}

.header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
}

.headerTitle {
  flex: 1;
  color: $dt-darkBlue;
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.idContainer {
  padding: 0px 20px;
  margin: 0 20px;
  border-width: 0 1px;
  border-style: solid;
  border-color: $dt-border;
}

.typeContainer {
  padding: 0 20px;
}

.flexSpacer {
  flex: 1;
}

.headerLabel {
  color: $dt-grey-3;
  font-size: 12px;
  font-weight: bold;
}

.headerVal {
  color: $dt-darkBlue;
  font-size: 20px;
  font-weight: bold;

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.modalContent {
  width: 100%;
  padding: 20px;
  @include edScrollbar;
}

.settings {
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
  background-color: $dt-header-background;
  border: 1px solid $dt-header-border;

  label {
    font-weight: bold;
  }

  :global {
    .row:not(:first-of-type) {
      margin-top: 20px;
    }

    .alert {
      height: 38px;
      line-height: 38px;
      margin-bottom: 0;
      padding: 0 1.25rem;
    }
  }
}

.verticalBottomCentered {
  display: flex;
  align-items: flex-end;

  :global {

    .react-toggle {
      vertical-align: middle;
      margin-right: 5px;
    }

    .material-icons {
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}

.linkToDetailPage {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
}