@import 'client/colors';

.NotificationCenter {
  margin-top: 20px;
}

.verticalCenteredContent {
  display: flex;
  align-items: center;
}

.hidden {
  visibility: hidden;
}