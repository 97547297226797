@import 'client/colors';

.NcSlideshowPlanDetails {
  color: inherit;
}

.starEndContainer {
  display: flex;
  align-items: center;

  label {
    font-weight: bold;
  }

  &>div {
    flex: 1;

    &:first-child {
      padding-right: 16px;
    }

    &:last-child {
      border-left: 1px solid $dt-border;
      padding-left: 16px;
    }
  }
}

.formRow:not(:first-child) {
  margin-top: 16px;
}