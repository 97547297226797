@keyframes slide {
  100% {
    left: 15px
  }
}

body {
  font-family: "Roboto", sans-serif !important;
  color: #444 !important;
  -webkit-font-smoothing: antialiased;
  background-color: #f6f6f6 !important
}

img {
  align-self: center
}

#App {
  overflow: hidden
}

.bgDark {
  background-color: #002e67 !important
}

.recharts-cartesian-axis-tick-value {
  font-weight: bold;
  font-size: 10px;
  letter-spacing: .1px;
  line-height: normal;
  color: #9faaae;
  fill: #9faaae
}

.recharts-legend-item-text {
  font-weight: bold;
  font-size: 12px;
  letter-spacing: .1px;
  line-height: normal
}

.edTooltip .tooltip-inner {
  background: #fff;
  color: #444;
  border: 1px solid #e5e5e5;
  border-bottom-color: #fff
}

.edTooltip .arrow::before {
  border-top-color: #fff !important;
  border-bottom-color: #fff !important
}

#Login {
  width: 400px;
  border: 1px solid #e5e5e5;
  padding: 20px;
  margin: auto;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}

#Login form {
  justify-content: center
}

#Login button {
  margin-left: 10px
}

#Header .dropdown .nav-link {
  padding: 0;
  color: #fff
}

#Header .dropdown .dropdown-menu button {
  cursor: pointer
}

#Header .dropdown .dropdown-menu a:hover {
  text-decoration: none
}

.edLoader {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite
}

@keyframes spin {
  0% {
    transform: rotate(0deg)
  }

  100% {
    transform: rotate(360deg)
  }
}

#GlobalError.globalErrorPage {
  max-width: 600px;
  margin: 100px auto;
  padding: 20px;
  border-radius: 4px;
  text-align: center
}

#GlobalError.globalErrorPage .buttons {
  margin-top: 10px
}

.PageTitle {
  font-family: "Roboto";
  font-size: 32px;
  font-weight: bold;
  letter-spacing: .8px;
  color: #002e67;
  padding: 30px 0
}

.btn-primary {
  background-color: #002e67 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border-color: #002e67 !important;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .2) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  letter-spacing: .5px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  height: 48px
}

.btn-primary:hover {
  background-color: #002048 !important
}

.btn-primary:active {
  box-shadow: none !important
}

.btn-outline-secondary {
  background-color: #fff !important;
  color: #9faaae !important;
  border-radius: 4px !important;
  border-color: #9faaae !important;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .2) !important;
  font-size: 14px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  letter-spacing: .5px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  height: 48px
}

.btn-outline-secondary:hover {
  background-color: #9faaae !important;
  color: #fff !important
}

.btn-outline-secondary:active {
  box-shadow: none !important
}

.btn-secondary:not(#pageDropDown) {
  background-color: #9faaae !important;
  color: #fff !important;
  border-radius: 4px !important;
  border-color: #9faaae !important;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .2) !important;
  font-size: 10px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  letter-spacing: .5px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  height: 24px;
  border: 0
}

.btn-secondary:not(#pageDropDown):hover {
  background-color: #6f777a !important
}

.btn-secondary:not(#pageDropDown):active {
  box-shadow: none !important
}

button.inputButton {
  height: 38px !important
}

.tableDiv .react-bs-table-sizePerPage-dropdown {
  margin-left: 20px
}

.tableDiv .react-bs-table-tool-bar {
  margin-bottom: 20px
}

.tableDiv .react-bs-table-tool-bar input {
  border-color: #e5e5e5
}

.tableDiv .react-bs-container-header {
  border-radius: 4px 4px 0 0;
  background-color: #edf0f4;
  border: 1px solid #e1e6ed
}

.tableDiv .react-bs-container-body {
  border-style: solid;
  border-color: #e5e5e5;
  border-width: 0px 1px
}

.tableDiv .react-bs-table-pagination {
  margin-top: 20px
}

.tableDiv .table.table-hover tbody tr:hover {
  background-color: #f6f6f6;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, .1)
}

.tableDiv .table thead th {
  border-bottom: none;
  border-top: none;
  text-overflow: unset
}

.tableDiv .table thead .sort-column {
  position: relative
}

.tableDiv .table thead .sort-column span.fa {
  margin: 0 !important
}

.tableDiv .table thead .sort-column span.fa::before {
  display: none
}

.tableDiv .table thead .sort-column span.fa:not(.order)::after {
  content: "";
  width: 30px;
  height: 4px;
  background-color: #002e67;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px 2px 0 0
}

.tableDiv .table tbody {
  background-color: #fff
}

.tableDiv .table td {
  font-size: 14px;
  color: #444;
  border: 0;
  border-bottom: 1px solid #e5e5e5
}

.tableDiv .table .ellipsisDiv {
  text-overflow: ellipsis;
  overflow: hidden
}

.tableDiv .table .actionsColumn {
  position: relative;
  overflow: visible
}

.tableDiv .table .actionsColumn .actionHoveringDiv {
  top: 50%;
  transform: translateY(-50%)
}

@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {
  .tableDiv .table .actionsColumn .actionHoveringDiv {
    top: inherit
  }
}

.tableDiv .table .actionHoveringDiv {
  position: absolute;
  right: 25px
}

.tableDiv .table .actionHoveringDiv .btn {
  display: none
}

.tableDiv .table .actionHoveringDiv .btn.alwaysVisible {
  display: inline-block
}

.tableDiv .table .actionHoveringDiv .btn:not(:last-child) {
  margin-right: 10px
}

.tableDiv .table tr:hover .actionHoveringDiv .btn {
  display: inline-block
}

.tableDiv .table tr:hover>.hideOnHover {
  opacity: 0
}

.tableDiv .table tr.verticalAligned td {
  vertical-align: middle
}

.tableDiv .table tr:not(:hover) th.bordered,
.tableDiv .table tr:not(:hover) td.bordered {
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #e5e5e5
}

.tableDiv .tableFooterDiv {
  padding: 20px;
  border: 1px solid #e5e5e5;
  border-top: none;
  border-radius: 0 0 4px 4px;
  text-align: center;
  background: #fff
}

.tableDiv .pagination {
  display: inline-flex
}

.tableDiv .pagination .page-item {
  margin: 0 5px
}

.tableDiv .pagination .page-item .page-link {
  background: none;
  border: none;
  color: #9faaae;
  font-size: 12px;
  padding: .75rem 1rem
}

.tableDiv .pagination .page-item .page-link:focus {
  outline: none;
  box-shadow: none
}

.tableDiv .pagination .page-item.active .page-link {
  color: #002e67;
  background: #edf0f4;
  z-index: unset
}

.tableDiv .pagination .page-item[title$=page] .page-link {
  background: #fff;
  color: #002e67;
  font-size: 14px;
  border-radius: 4px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .3);
  border: 1px solid rgba(0, 0, 0, 0)
}

.tableDiv .pagination .page-item[title$=page] .page-link:hover {
  border-color: #002e67
}

.tableDiv .pagination .page-item[title$=page] .page-link:active {
  background: #002e67;
  color: #fff
}

.tableDiv #pageDropDown {
  background: #fff;
  color: #002e67;
  font-size: 14px;
  border: none;
  vertical-align: top;
  padding: .65rem 1rem;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, .3)
}

.tableDiv #pageDropDown:focus {
  outline: none;
  box-shadow: none
}

.tableDiv .goToPageDiv {
  text-align: right;
  overflow: hidden;
  white-space: nowrap
}

.tableDiv .goToPageDiv input,
.tableDiv .goToPageDiv button {
  display: inline-block;
  padding: .65rem 1rem;
  width: auto;
  font-size: 14px;
  border: 1px solid #e5e5e5;
  border-radius: 4px
}

.tableDiv .goToPageDiv input:focus {
  border-color: #002e67;
  color: #002e67
}

.tableDiv .goToPageDiv button {
  margin-left: 20px;
  background: #fff;
  color: #002e67;
  font-size: 14px;
  border: 1px solid #e5e5e5
}

.tableDiv .goToPageDiv button:hover {
  border-color: #002e67
}

.tableDiv .goToPageDiv button:active {
  background: #002e67;
  color: #fff
}

.tableDiv .goToPageDiv button:focus {
  outline: none;
  box-shadow: none
}

.limitedHeight {
  display: none
}

.withLoader {
  position: relative;
  min-height: 100px
}

.modal-body {
  background-color: #f6f6f6;
  max-height: calc(100vh - 200px);
  overflow: auto;
  overflow: overlay
}

.modal-body::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: rgba(0, 0, 0, 0)
}

.modal-body::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0);
  outline: 0 solid rgba(0, 0, 0, 0)
}

.modal-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0)
}

.modal-body {
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

.modal-body:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

.modal-body:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

.modal-body:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid rgba(0, 0, 0, 0)
}

.modal-body:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .05)
}

.react-toggle-track {
  border-radius: 4px !important;
  background-color: #9faaae !important
}

.react-toggle--checked .react-toggle-track {
  background-color: #63bd04 !important
}

.react-toggle-thumb {
  border-radius: 4px !important;
  border: none !important
}

.form-control:focus {
  box-shadow: none !important;
  border: 1px solid #002e67 !important;
  color: #002e67 !important
}

@keyframes hiding {
  100% {
    position: fixed;
    top: 3000px;
    left: 3000px
  }
}

.translated.en {
  color: green !important
}

.translated.nl {
  color: red !important
}

.swal2-show.noBounce {
  animation: none
}

.swal2-show.noBounce.shake {
  animation: shake .5s !important
}

@keyframes shake {

  from,
  to {
    transform: translate3d(0, 0, 0)
  }

  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate3d(-10px, 0, 0)
  }

  20%,
  40%,
  60%,
  80% {
    transform: translate3d(10px, 0, 0)
  }
}

body>.scaletext-wrapper {
  visibility: hidden !important;
  left: -20px !important
}

.react-datepicker__input-time-container {
  margin: 5px 0 10px 0px !important;
  padding: 0 15px !important;
  float: unset !important;
  display: flex !important;
  align-items: center !important
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  flex: 1 !important
}

.react-datepicker__input-time-container .react-datepicker-time__input-container input {
  width: inherit !important
}

.hideTimeInput .react-datepicker__input-time-container {
  display: none !important
}

.swal2-footer .alert {
  overflow: auto
}

.tox .tox-collection__item[title="Table properties"],
.tox .tox-collection__item[title="Row properties"],
.tox .tox-collection__item[title="Cell properties"] {
  display: none !important
}

.search-label {
  display: block !important
}