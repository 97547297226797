.portrait1 {
  margin: auto;
  max-width: 600px;
}

.landscape {
  position: relative;
  padding-top: 50%;
}

.portrait2 {
  position: relative;
  width: 100%;
  padding-top: 75%;
  margin: auto;
}

.link {
  cursor: pointer;
}

.inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.fullWidth {
  width: 100%;
}