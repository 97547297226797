.SideBarItem {
  width: 100px;
  padding: 15px 0;
  color: #002e67;
  text-align: center;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  letter-spacing: 1px;
  font-style: normal;
  font-stretch: normal;
  border-radius: 4px;
  line-height: normal
}

.SideBarItem .iconContainer {
  font-size: 35px
}

.SideBarItem .textContainer {
  margin-top: 10px
}

.SideBarItem:hover {
  background-color: #F6F6F6
}

.active .SideBarItem {
  background: #ee7d11;
  color: #ffffff
}