@import "client/colors";

.toolbarRight {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.table {
  width: 100%;
  background: white;
  border: 1px solid $dt-border;
  border-radius: 4px;

  &:first-child {
    border-top: 0px;
  }

  td,
  th {
    height: 46px;
  }


  th {
    text-align: center;
    padding: 0px;
    min-width: 80px;

    &:last-child {
      min-width: 0px;
    }
  }

  tbody tr {
    border-top: 1px solid $dt-border;
  }
}

.tableHeader {
  background-color: $dt-header-background;
  width: 100%;
}

.grey td {
  color: #888;
}

.lightGrey td {
  color: #BBB;
}

.row {
  position: relative;
  font-size: 14px;
  align-items: center;
  cursor: pointer;
}

.rowWithOverlay {
  &:hover {
    background-color: rgba(0, 0, 0, .075);

    .textCell {
      opacity: 0
    }

    .rowOverlay {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 24px;
      display: flex;
      align-items: center;
    }
  }
}

.rowOverlay {
  display: none;
}

.greyedRow {
  opacity: 0.5 // background-color: rgba(0, 0, 0, .075);
}

.firstCell {
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0px 4px;
}

.textCell {
  white-space: nowrap;
  text-align: center;
  padding: 0px 6px;
}

.toggle {
  flex: 0 0 40px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  color: #444;
}

/*copied from DataTable*/
.btn {
  margin-left: 10px;
  background-color: $dt-grey-3 !important;
  color: #fff !important;
  border-radius: 4px !important;
  border-color: $dt-grey-3 !important;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .2) !important;
  font-size: 10px !important;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  letter-spacing: .5px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  height: 24px;
  border: 0;
  display: inline-block;

  &:not(:disabled):hover {
    background-color: #6f777a !important;
  }
}