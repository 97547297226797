@import "client/colors";

.pluginContainer {
    margin-top: 20px;
}

.externalIFrame {
    border: none;
    width: 1px;
    min-width: 100%;
    height: calc(-300px + 100vh);
}