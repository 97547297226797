@import 'client/colors';

.imagePreview {
  margin-top: 10px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  border: 1px solid $dt-border;
  height: 170px;
  position: relative;

  span {
    position: absolute;
    top: 5px;
    right: 5px;
    color: red;
    cursor: pointer;
  }
}

.withError {
  border: 1px solid red !important;
}

.extraText {
  font-size: 12px;
  color: $dt-button-grey;
  text-transform: none;
}

.errorMsg {
  color: red;
  font-size: 14px;
  margin-top: 10px;
}