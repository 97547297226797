#GroupFilter {
  position: absolute;
  right: 30px;
  top: 10px;
  text-transform: uppercase;
  font-size: 10px;
  widows: 100%
}

#GroupFilter select {
  height: 24px;
  width: 120px;
  font-size: 10px;
  display: inline-block;
  margin-left: 7px;
  border-width: 1px;
  border-radius: 4px;
  border-color: #9faaae;
  padding: 0px 0.75rem
}