@import "client/colors";

.CmsContentEditor {
  color: inherit;
  padding: 20px;
  height: 100%;

  :global {
    #WidgetSelector,
    #FormSelector {
      position: fixed;
    }

    .tox-editor-header {
      display: flex !important;
      flex-direction: column;
      align-items: center;
      // background-color: $dt-background !important;
    }
    // .tox-toolbar-overlord {
    //   max-width: 840px;
    // }

    .tox-sidebar-wrap {
      flex-direction: column !important;
      align-items: center;
      background-color: $dt-background;
    }
    .tox-edit-area {
      max-width: 840px;
      width: 100%;
    }
    .tox-toolbar {
      justify-content: center;
      &:first-child {
        margin-bottom: 8px;
      }
    }
    .tox-toolbar:not(:first-child) {
      display: none;
      background: none;
      border-top: 1px solid #e3e3e3;
      padding-top: 8px;
    }

    .tox-tbtn--disabled .tox-icon {
      opacity: 0.3;
    }
    .tox-tbtn__select-label {
      font-weight: 500 !important;
    }

    .tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
      border-right: 1px solid #e3e3e3;
    }
  }
}

:global {
  .tox .tox-collection__item-label {
    font-weight: 500 !important;
  }
}
