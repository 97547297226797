#AvgArticlesPerUserChart {
  margin-top: 20px;
  width: 100%;
  height: 380px
}

#AvgArticlesPerUserChart .chart-header {
  text-align: center
}

#AvgArticlesPerUserChart .chartPanel {
  display: flex;
  padding: 20px;
  align-items: center
}

#AvgArticlesPerUserChart .chartPanel .separator {
  flex: 1
}

#AvgArticlesPerUserChart .chartPanel .chartMetrics {
  display: flex
}

#AvgArticlesPerUserChart .chartPanel .chartMetrics .chartMetric {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold
}

#AvgArticlesPerUserChart .chartPanel .chartMetrics .chartMetric.primary {
  color: #002e67
}

#AvgArticlesPerUserChart .chartPanel .chartMetrics .chartMetric.secondary {
  color: #009BC2;
  margin-left: 40px
}

#AvgArticlesPerUserChart .chartPanel .chartMetrics .chartMetric .metricLabel {
  margin: 0 10px;
  letter-spacing: 0.5px
}

#AvgArticlesPerUserChart .chartPanel .chartMetrics .chartMetric .metricValue {
  color: #444;
  font-size: 18px
}

#AvgArticlesPerUserChart .chartPanel .chartControl {
  font-size: 12px;
  text-transform: uppercase
}

#AvgArticlesPerUserChart .chartPanel .chartControl .groupByFilter {
  display: flex;
  align-items: center
}

#AvgArticlesPerUserChart .chartPanel .chartControl .groupByFilter .label {
  white-space: nowrap;
  margin-right: 10px
}

#AvgArticlesPerUserChart .chartPanel .chartControl .groupByFilter select {
  font-size: 12px;
  display: inline-block
}