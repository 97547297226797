@import 'client/colors';

.NcSettingsTile {
  flex: 1;
  border: 1px solid $dt-button-grey;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 32px 16px;
  cursor: pointer;
  position: relative;

  &:hover {
    border-color: $dt-orange;
  }
}

.disabledTile {
  cursor: not-allowed;
  opacity: 0.5;

  &:hover {
    border-color: $dt-button-grey;
  }
}

.title {
  color: $dt-darkBlue;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

.descr {
  font-size: 14px;
}

.additionalInfo {
  position: absolute;
  top: 8px;
  right: 8px;
}