@import "client/colors";

.ReportedContent {
  margin-top: 20px;

  :global {
    .status {
      font-size: 12px;

      &.status-visible {
        color: $dt-green;
      }
      &.status-rejected {
        color: $errorRed;
      }
    }
  }
}

.allReviewedIcon {
  color: $dt-green;
  margin-left: 8px;
  visibility: hidden;
  &.visible {
    visibility: visible;
  }
  &.rejected {
    color: $errorRed;
  }
}

.dateFiltersContainer {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;

  :global {
    .react-datepicker__input-container {
      position: relative;

      &::after {
        content: "From:";
        position: absolute;
        top: 50%;
        width: auto;
        left: 10px;
        transform: translateY(-50%);
        font-size: 14px;
        color: $dt-button-grey;
      }

      input {
        padding-left: 50px;
        min-width: 160px;
      }
    }
  }

  > div:last-of-type {
    input {
      padding-left: 35px;
    }

    :global .react-datepicker__input-container::after {
      content: "To:";
    }
  }
}

.datePickerPopper {
  left: 30px !important;
  top: 20px !important;
}

.maxPeriodRangeText {
  text-align: left;
  font-style: italic;
  color: $dt-orange;
  padding: 10px 5px 5px 5px;
}

.pendingReportsToggleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  span {
    font-size: 14px;
    cursor: pointer;
  }
}

.titleOfArticle {
  font-weight: bold;
}

.titleOfPost {
  font-style: normal;
}
