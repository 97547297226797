@import 'client/colors';

.CmsItemDetails {
  color: inherit;

  :global {

    label {
      font-weight: bold;
    }

    .publishingDateWrapper {
      position: relative;

      &::after {
        position: absolute;
        pointer-events: none;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        color: $dt-darkBlue;
        font-family: 'FontAwesome';
        content: '\f073';
      }

      :global {

        .react-datepicker-wrapper {
          width: 100%;

          .react-datepicker__input-container {
            width: 100%;

            .form-control.is-invalid {
              background-image: none;
            }
          }

          .react-datepicker__close-icon {
            right: 37px;
          }

          .react-datepicker-time__input {
            min-width: 100px;
          }

          input {
            font-size: 14px;
            color: $dt-darkBlue;
          }
        }

        .react-datepicker-popper {
          z-index: 2;
        }
      }
    }

  }
}

.idContainer {
  padding: 0px 20px;
  margin: 0 20px;
  border-width: 0 1px;
  border-style: solid;
  border-color: $dt-border;
}

.headerLabel {
  color: $dt-grey-3;
  font-size: 12px;
  font-weight: bold;
}

.headerVal {
  color: $dt-darkBlue;
  font-size: 20px;
  font-weight: bold;

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.titleInput {
  height: 48px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.draftBadge {
  background-color: $dt-orange !important;
  text-transform: uppercase;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  margin-right: 16px;
}

.deleteButton {
  text-transform: uppercase;
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: 0.5px;
}

.adjustHeightDueToWarning {
  height: calc(100% - 90px - 50px)
}

.qrContainer {
  padding: 0 20px;
  display: flex;
  align-items: center;

  :global {
    .qrToggler {
      cursor: pointer;
      font-size: 32px;
      color: $dt-darkBlue;
    }
  }
}