@import "client/colors";

.batchActionsContainer {
    position: fixed;
    bottom: 50px;
    left: calc(50% + 120px);
    transform: translateX(calc(-50% - 60px)); // translateX(-120px);
    padding: 16px 24px;
    border: 1px solid $dt-button-grey;
    border-radius: 8px;
    background: $dt-background;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;

    :global {
        .btn {
            white-space: nowrap;

            &:not(:last-child) {
                margin-right: 16px;
            }
        }
    }
}