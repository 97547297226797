@import 'client/colors';

.ArticlePreview {
  color: inherit;
  display: flex;
  flex-direction: column;
  gap: 8px;
  text-align: left;
}

.flexRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.description {
  // font-size: 14px;
  padding: 8px 0;
}


.imageContainer {
  background-color: $dt-background;
  height: 140px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.readMore {
  font-weight: bold;
}