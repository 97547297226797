@import "client/colors";

.CmsFeedVariations {
  margin-top: 10px;
}

.clickableText {
  color: $dt-darkBlue;
  text-decoration: underline;
  cursor: pointer;
}