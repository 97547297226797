@import 'client/colors';

.CmsVariations {
  color: inherit;
  position: relative;

  ul {
    list-style-type: square;
    margin-bottom: 0;
    padding-inline-start: 20px;

    li {
      font-size: 14px;
      text-transform: capitalize;
    }
  }
}

.clickable {
  color: $dt-darkBlue;
  cursor: pointer;
  text-decoration: underline;
}

.addItemButton {
  margin-top: 10px;
  color: $dt-darkBlue;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}