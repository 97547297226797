@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#SideBar {
  position: fixed;
  height: 100vh;
  width: 120px;
  -webkit-box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 99;
  background: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column
}

#SideBar .logoSideBar {
  height: 100px;
  padding: 0 10px
}

#SideBar .logoSideBar .SideBarItem {
  padding: 10px 0 !important
}

#SideBar .logoSideBar .SideBarItem:hover {
  background-color: transparent !important
}

#SideBar .mainSideBar {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: auto;
  overflow: overlay;
  overflow-x: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  position: relative;
  padding: 0 10px
}

#SideBar .mainSideBar::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#SideBar .mainSideBar::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#SideBar .mainSideBar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#SideBar .mainSideBar {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#SideBar .mainSideBar:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#SideBar .mainSideBar:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#SideBar .mainSideBar:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#SideBar .mainSideBar:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#SideBar .mainSideBar .scrollableWrapper {
  height: 644px;
  flex: auto;
}

#SideBar .mainSideBar .separator {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

#SideBar .settingsItem {
  border-width: 1px 0px;
  border-style: solid;
  border-radius: 0;
  border-color: #E5E5E5;
  height: 50px;
  width: 100%;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

#SideBar .settingsItem .iconContainer {
  text-align: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

#SideBar .settingsItem .iconContainer a {
  display: inherit
}

#SideBar .settingsItem .iconContainer:first-child {
  border-right: 1px solid #E5E5E5
}

#SideBar .nav-item {
  padding: 0
}

#SideBar .nav-item.disabled {
  opacity: 0.3;
  pointer-events: none
}

#SideBar .nav-item .nav-link {
  padding: 0
}

#SideBar_settingsMenu .horizontalLine {
  position: absolute;
  left: 0;
  width: 100%;
  height: 2px;
  border-top: 1px solid #E5E5E5
}

#SideBar_settingsMenu .verticalLine {
  position: absolute;
  left: 0;
  border: 0;
  width: 1px;
  height: 100%;
  border-left: 1px solid red
}

#SideBar_settingsMenu .menuItem {
  padding: 0px 20px;
  font-family: 'Roboto';
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 1px;
  color: #002e67;
  text-transform: uppercase;
  text-align: left;
  margin: 20px 0
}

#SideBar_settingsMenu .menuItem.disabled {
  opacity: 0.3;
  pointer-events: none
}

#SideBar_settingsMenu .menuItem.disabled a {
  pointer-events: none
}

#SideBar_settingsMenu .menuItem a {
  color: #002e67;
  text-transform: uppercase;
  text-decoration: none
}

#SideBar_settingsMenu .menuItem img {
  vertical-align: middle;
  margin-right: 5px
}

#SideBar_settingsMenu .loggedItem {
  text-transform: none;
  padding: 0 5px;
  margin: 5px 0;
  font-weight: normal
}

#SideBar_settingsMenu .loggedItem i.fa {
  color: #96BF0D
}

#SideBar_settingsMenu .versionContainer {
  text-align: right;
  font-size: 10px;
  color: #002e67
}