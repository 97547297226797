@import "client/colors";
@import "client/mixins";
@import "client/typography";


.mediaManagerContainer {
  width: 100%;
  display: flex;
  flex-direction: column;

  :global {
    .tab-content {
      @include edScrollbar;
    }
  }

}


.filters {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
  width: 100%;
}


.search {
  display: flex;
  padding: 10px 12px;
  align-items: center;
  gap: 10px;
  flex: 1 0 0;
}

.singleSelect {
  width: 180px;
}

.uploadButton {
  height: 40px;
  font-size: 12px !important;
}

.gap24 {
  height: 24px;
  width: 100%;
}

.searchResults {
  padding-bottom: 24px;
  color: $dt-grey-3;
  @include titleFont;
}

.list {
  width: 100%;
}

.grid {
  margin: -12px;
}

.foldersList {
  position: relative;
}

.foldersGrid {
  border-top: 1px solid $dt-border;
  border-bottom: 1px solid $dt-border;
  padding-top: 24px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  gap: 24px;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
}