@import 'client/colors';

@mixin verticalFlex {
  display: flex;
  flex-direction: column;
}

@mixin remainingHeightVerticalFlex {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.NcDevicePlaylist {
  padding: 0 20px;
  height: 100%;
  @include verticalFlex();
}

.playlistSection {
  @include remainingHeightVerticalFlex();
}

.playlistSectionTitle {
  color: $dt-darkBlue;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  padding: 20px 0;
}

.playlistSectionContent {
  @include remainingHeightVerticalFlex();
  position: relative;
}

.loaderWrapper {
  position: absolute;
  top: 50%;
  right: 50%;
}

/* HTML: <div class="loader"></div> */
.inlineLoader {
  animation: l1 1s linear infinite alternate;
  font-weight: bold;
  font-size: 12px;
  display: inline;
  margin-left: 8px;
  text-transform: none;
  color: $dt-blue;

  &:before {
    content: "Loading..."
  }
}

@keyframes l1 {
  to {
    opacity: 0
  }
}