@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#NotificationResults {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-top: 1px solid #E5E5E5
}

#NotificationResults .headerDiv {
  color: #002e67;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  padding: 30px 0
}

#NotificationResults .footerDiv {
  text-align: center;
  padding: 30px 0
}

#NotificationResults .footerDiv .btn-secondary {
  font-size: 10px;
  font-weight: bold;
  border: 0;
  padding: 7px 20px;
  border-radius: 4px;
  line-height: normal;
  -webkit-box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
  height: 24px;
  text-transform: uppercase;
  background-color: #9faaae
}

.CustomChartTooltip {
  background-color: #ffffff;
  padding: 2px 10px;
  border: 1px solid #E5E5E5;
  font-family: 'Roboto';
  font-size: 12px;
  color: #9faaae;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 24px
}

.CustomChartTooltip:before {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
  content: '';
  height: 0;
  width: 0;
  border: 6px solid transparent;
  z-index: 1;
  border-right-color: #E5E5E5
}

.CustomChartTooltip:after {
  position: absolute;
  left: 1px;
  top: 50%;
  -webkit-transform: translate(-100%, -50%);
  transform: translate(-100%, -50%);
  content: '';
  height: 0;
  width: 0;
  border: 6px solid transparent;
  z-index: 2;
  border-right-color: #ffffff
}