@import 'client/colors';

.NcSlideshows {
  color: inherit;
}


.timezoneContainer {
  font-style: italic;
  font-weight: bold;
  font-size: 12px;
  text-align: left;
  letter-spacing: 1px;
}