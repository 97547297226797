@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#WidgetDetails .modal-body {
  overflow: visible
}

#WidgetDetails .datePickerWrapper {
  position: relative
}

#WidgetDetails .datePickerWrapper .react-datepicker-wrapper {
  width: 100%
}

#WidgetDetails .datePickerWrapper .react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%
}

#WidgetDetails .datePickerWrapper .react-datepicker-wrapper .react-datepicker__input-container .form-control.is-invalid {
  background-image: none
}

#WidgetDetails .datePickerWrapper .react-datepicker-wrapper .react-datepicker__close-icon {
  right: 37px
}

#WidgetDetails .datePickerWrapper .react-datepicker-wrapper input {
  font-size: 14px;
  color: #002e67
}

#WidgetDetails .datePickerWrapper::after {
  position: absolute;
  pointer-events: none;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #002e67;
  font-family: 'FontAwesome';
  content: '\f073';
  height: 28px
}

#WidgetDetails .datePickerWrapper .react-datepicker-popper {
  z-index: 2
}