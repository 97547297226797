.cont1 {
  overflow: hidden;
}

.cont2 {
  margin: 0 auto;
}

.cont3 {
  position: relative;
}

.cont4 {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
}