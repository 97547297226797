@import 'client/colors';

.NewsletterContentSlot {
  flex: 1;
  text-align: center;
  align-items: center;
  position: relative;
}

.nonEmptySlot {
  overflow-wrap: anywhere;
}

.metaInfo {
  position: absolute;
  left: 50%;
  top: 0px;
  transform: translate(-50%, -40px);
  color: silver;
  font-size: 12px;
}