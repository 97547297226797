@import 'client/colors';

.CmsPublishDialog {
  color: inherit;
  font-size: 14px;
  min-height: 100px;

  
  label {
    margin-top: 16px;
    font-weight: bold;
  }
    :global {
  
  
      .publishingDateWrapper {
        position: relative;
  
        &::after {
          position: absolute;
          pointer-events: none;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          color: $dt-darkBlue;
          font-family: 'FontAwesome';
          content: '\f073';
        }
  
        :global {
  
          .react-datepicker-wrapper {
            width: 100%;
  
            .react-datepicker__input-container {
              width: 100%;
  
              .form-control.is-invalid {
                background-image: none;
              }
            }
  
            .react-datepicker__close-icon {
              right: 37px;
            }
  
            .react-datepicker-time__input {
              min-width: 100px;
            }
  
            input {
              font-size: 14px;
              color: $dt-darkBlue;
            }
          }
  
          .react-datepicker-popper {
            z-index: 2;
          }
        }
      }
  
    }
  }

