@import 'client/colors';


.warningTextWrapper {
  display: inline;
  margin-left: 8px;
  cursor: help;

  i {
    color: $dt-orange;
  }
}