@import "client/colors";

.CmsItemsTabs {
  display: flex;
  height: 100%;
}

.CmsItemsTab {
  align-self: flex-end;
  display: flex;
  align-items: center;

  color: $dt-button-grey;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  background: #f6f6f6;

  .CmsItemsTabInner {
    border-color: $dt-header-border;
    border-style: solid;
    padding: 16px 24px;
  }

  &:not(.active) {
    .CmsItemsTabInner {
      background-color: #ffffff;
      cursor: pointer;
    }
  }

  &.active {
    .CmsItemsTabInner {
      box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);
      padding: 20px 24px;
      background-color: $dt-header-background;
      border-width: 1px 1px 0px 1px;
      border-radius: 4px 8px 0px 0px;
    }
  }

  &:first-child:not(.active) {
    .CmsItemsTabInner {
      border-width: 1px 0px 0px 1px;
      border-radius: 8px 0px 0px 0px;
    }
  }

  &:last-child:not(.active) {
    .CmsItemsTabInner {
      border-width: 1px 1px 0px 0px;
      border-radius: 0px 8px 0px 0px;
    }
  }

}