#VisitsPerHourOfDayChart {
  margin-top: 20px;
  width: 100%;
  height: 380px
}

#VisitsPerHourOfDayChart .loaderContainer {
  position: relative;
  min-height: 285px
}

#VisitsPerHourOfDayChart .loaderWrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center
}

#VisitsPerHourOfDayChart .chartPanel {
  display: flex;
  padding: 20px
}

#VisitsPerHourOfDayChart .chartPanel .chartTitle {
  color: #002e67;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold
}

#VisitsPerHourOfDayChart .chartPanel .separator {
  flex: 1
}

#VisitsPerHourOfDayChart .chartPanel .chartScales {
  display: flex;
  font-size: 12px
}

#VisitsPerHourOfDayChart .chartPanel .chartScales .chartScale {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  margin: 0 3px
}

#VisitsPerHourOfDayChart .chartPanel .chartScales .chartScale .chartScaleSquare {
  background-color: #002e67;
  min-width: 20px;
  min-height: 20px;
  color: transparent
}

#VisitsPerHourOfDayChart .chartPanel .chartScales .chartScale .chartScaleNum {
  color: #444;
  font-weight: bold;
  text-align: center
}

#VisitsPerHourOfDayChart .chartBody {
  padding: 20px
}

#VisitsPerHourOfDayChart .heatMap {
  font-size: 12px
}

#VisitsPerHourOfDayChart .heatMap .heatMap-row {
  display: flex
}

#VisitsPerHourOfDayChart .heatMap .heatMap-row .heatMap-cell {
  flex: 1;
  text-align: center;
  margin: 3px;
  min-width: 15px;
  color: white
}

#VisitsPerHourOfDayChart .heatMap .heatMap-row .heatMap-cell.data-cell {
  background-color: #002e67;
  color: transparent;
  cursor: pointer
}

#VisitsPerHourOfDayChart .heatMap .heatMap-row .heatMap-cell.data-cell.dark:hover {
  color: white
}

#VisitsPerHourOfDayChart .heatMap .heatMap-row .heatMap-cell.data-cell.light:hover {
  color: black
}

#VisitsPerHourOfDayChart .heatMap .heatMap-row .heatMap-cell.label-cell {
  color: #9faaae;
  font-weight: bold;
  text-transform: uppercase
}

#VisitsPerHourOfDayChart .heatMap .heatMap-row .heatMap-cell.label-cell:first-child {
  text-align: right
}