@import "client/colors";
@import "client/mixins";

.NewsletterAnalytics {
  position: relative;
  min-height: 100px;
}

.analyticsRow {
  display: flex;
  .headerDiv {
    font-weight: bold;
  }
  .valueDiv {
    flex: 1;
    text-align: right;
    font-weight: bold;
  }
}

.articleAnalytics {
  margin-top: 8px;
  .articleAnalyticsRow {
    display: flex;
    padding: 8px 8px;
    &:nth-child(odd) {
      background-color: $dt-border;
    }
  }

  .articleTitle {
    flex: 1;
  }

  .articleClicks {
    text-align: right;
  }
}
