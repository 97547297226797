@import 'client/colors';

.NewsletterTemplatesetPreview_Content {
  color: inherit;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0px 16px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}