@import "client/colors";


.UsersModal {
  min-height: 400px;
  // :global {
  //   // .modal-title{
  //   //   align-items: center;
  //   // }
  // }
}