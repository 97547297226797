@import "client/colors";

.AudienceEstimatedSizeModal {
  label {
    font-weight: bold;
  }
}

.sectionContent {
  margin-top: 10px;
  font-size: 14px;
  text-align: justify;
}