@import "client/colors";

.ThreeStateToggle {
  display: flex;
  align-items: center;
  height: 100%;
}

.setToggle {
  font-size: 14px;
  margin-left: 10px;
  color: $dt-darkBlue;
  cursor: pointer;
  text-decoration: underline;
}

.undefined {
  font-size: 14px;
  margin-left: 10px;
  color: $dt-grey-3;
  font-style: italic;
}