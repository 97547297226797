@import "../../../../colors";
@import "../../../../mixins";

#ArticleDetails {
  @include fullPageModal();
}

.header {
  padding: 20px;
  border-bottom: 1px solid #e5e5e5;
  position: relative;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
  display: flex;
}

.idContainer {
  padding: 0px 20px;
  margin: 0 20px;
  border-width: 0 1px;
  border-style: solid;
  border-color: $dt-border;
}

.linkContainer {
  padding: 0 20px;
}

.flexSpacer {
  flex: 1;
}

.qrContainer {
  padding: 0 20px;
  display: flex;
  align-items: center;

  :global {
    .qrToggler {
      cursor: pointer;
      font-size: 32px;
      color: $dt-darkBlue;
    }
  }
}

.headerLabel {
  color: $dt-grey-3;
  font-size: 12px;
  font-weight: bold;
}

.headerVal {
  color: $dt-darkBlue;
  font-size: 20px;
  font-weight: bold;

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.notFoundMessage {
  flex: 1;
  text-align: center;
  margin: 20px;
}

.articlePreviewWrapper {
  @include edScrollbar;
  padding: 20px;
  height: 100%;
}

.articlePreviewContainer {
  :global {
    #ArticleContent {
      border: 1px solid $dt-border;
    }
  }
}

.detailsContainer {
  padding: 20px;
}

.detailsSection {
  display: flex;
  padding: 20px 0;
  border-bottom: 1px solid $dt-border;
  align-items: center;

  :global {
    .react-toggle {
      vertical-align: middle;
    }
  }
}

.detailsSectionLabel {
  text-transform: uppercase;
  color: $dt-darkBlue;
  font-weight: bold;
  font-size: 12px;
}

.detailsSectionValue {
  margin-top: 10px;
  font-size: 14px;
  font-weight: normal;
}

.resourceLink {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  :global {
    i {
      font-size: 10px;
      cursor: pointer;
      margin-right: 5px;

      &.status-0 {
        color: #d0021b;
      }

      &.status-1 {
        color: $dt-green;
      }
    }

    a {
      color: $dt-darkBlue !important;
      text-decoration: underline !important;
    }
  }
}


.tagsContainer {
  display: flex;
  flex-wrap: wrap;

  :global {
    .badge {
      margin-bottom: 10px;
      margin-right: 10px;
      padding: 6px 8px;
      background: transparent;
      font-size: 14px;
      font-weight: normal;
      // background-color: $dt-darkBlue;
      color: $dt-black;
      border: 1px solid $dt-button-grey;
      border-radius: 5px;
    }
  }
}