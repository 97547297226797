@import "../../colors";

.SortingModal {
  color: inherit;
}

.isDragging {
  background-color: $dt-header-background !important;
}

.scrollable {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.sortableItem {
  display: flex;
  align-items: center;
  padding: 10px;
  // background-color: $dt-background;
  background-color: white;
  border: 1px solid $dt-border;
  border-radius: 5px;
  margin-bottom: 20px;
}

.sortableItemLabel {
  font-size: 12px;
  // font-weight: bold;
  color: $dt-button-grey;
}

.sortableIndex {
  flex: 1;
  color: $dt-button-grey;
  font-size: 18px;
}

.sortableColumn {
  flex: 4;
}