@import 'client/colors';

.NcPlaylistSlideshowBox {
  width: 100%;
  border: 1px solid $dt-button-grey;
  margin-bottom: 16px;
  border-radius: 4px;
}

.boxHeader {
  padding: 16px;
  width: 100%;
  // border: 1px solid $dt-button-grey;
  // text-align: center;
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: left;
  font-weight: bold;
  cursor: pointer;
}

.slideTitle {
  flex: 1;
}

.totalSlides {
  color: inherit;
}

.slidesTable {
  width: 100%;
  table-layout: fixed;

  th,
  td {
    padding: 8px 16px;
  }

  thead {
    border: 1px solid $dt-header-border;
  }

  th {
    text-transform: uppercase;
    font-size: 10px;
    background-color: $dt-header-background;
    color: $dt-button-grey;
  }

  td:first-child {
    width: 100%;
  }

  tr:not(:last-child) {
    border-bottom: 1px solid $dt-header-border;
  }
}

.active {
  border-color: $dt-orange;

  .boxHeader {
    color: white;
    background-color: $dt-orange;
  }
}

.noSlidesRow {
  text-align: center;
  font-style: italic;
}

.noWrapDiv {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}