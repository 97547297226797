#UserHabitsChart {
  margin-top: 20px;
  width: 100%;
  height: 380px
}

#UserHabitsChart .loaderContainer {
  position: relative;
  min-height: 285px
}

#UserHabitsChart .loaderWrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center
}

#UserHabitsChart .chartPanel {
  display: flex;
  padding: 20px
}

#UserHabitsChart .chartPanel .chartTitle {
  color: #002e67;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold
}

#UserHabitsChart .chartPanel .separator {
  flex: 1
}

#UserHabitsChart .chartBody {
  padding: 20px
}