@import "../../../../colors";

.Audiences {
  position: relative;
  min-height: 100px;
  margin-top: 20px;
}

.labelIcon {
  vertical-align: middle;
  margin-right: 5px;
  color: $dt-orange;
}