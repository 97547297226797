#SourceSelector {
  margin-top: 20px
}

#SourceSelector .titleDiv {
  color: #002e67;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold
}

#SourceSelector :focus {
  outline: unset
}

#SourceSelector .table tr[width]:hover {
  background-color: unset
}

#SourceSelector .table td[colspan] {
  padding: 0;
  padding-left: 20px
}

#SourceSelector .table td[colspan] .table {
  border: 0px
}

#SourceSelector .table td[colspan] .react-bs-container-body {
  border: 0px
}