@import "client/colors";
@import "client/mixins";

.NewsletterDetails {

  @include fullPageModal();

  // :global {
  //   .btn-outline-secondary:disabled{
  //     cursor: not-allowed;
  //   }
  // }

}

.content {
  position: relative;
}

.header {
  display: flex;
  align-items: center;

  >*:not(:first-child) {
    margin-left: 20px;
  }
}

.nameInput {
  height: 48px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.settingsContainer {
  padding: 0 20px;
}

.section {
  padding: 20px 0;
}

.sectionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
}

.sectionTitle {
  color: $dt-darkBlue;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.sectionContent {
  margin-top: 20px;
  font-size: 14px;

}

.flexRow {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 15px;
}

.toggleRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.inlineLabel {
  font-weight: bold;
  text-transform: capitalize;
}

.groupsContainer {
  border: 1px solid $dt-border;
  border-radius: 5px;
  min-height: 100px;
  padding: 5px;
  position: relative;
}

.statusRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.status {
  font-weight: bold;
}

.lastSeenRow {
  margin-bottom: 5px;
  color: $dt-button-grey;
}

.groupRow {
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
    cursor: pointer;
  }

  input {
    cursor: pointer;
    margin-right: 5px;
  }

}

.status-disconnected {
  color: $dt-button-grey;
}

.status-offline {
  color: $errorRed;
}

.status-online {
  color: $dt-green;
}