#SourcesOverviewTable {
  padding-top: 20px
}

#SourcesOverviewTable :focus {
  outline: unset
}

#SourcesOverviewTable .table tr[width]:hover {
  background-color: unset
}

#SourcesOverviewTable .table td[colspan] {
  padding: 0;
  padding-left: 20px
}

#SourcesOverviewTable .table td[colspan] .table {
  border: 0px
}

#SourcesOverviewTable .table tr>td>.btn {
  visibility: hidden
}

#SourcesOverviewTable .table tr:hover>td>.btn {
  visibility: visible
}

#SourcesOverviewTable .status-active {
  color: #96BF0D;
  font-size: 10px
}

#SourcesOverviewTable .status-inactive {
  color: #d0021b;
  font-size: 10px
}