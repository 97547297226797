@import "client/colors";

.DatePicker {
  color: inherit;
  position: relative;

  :global {
    .react-datepicker-wrapper {
      width: 100%;

      .react-datepicker__input-container {
        width: 100%;
      }

      .react-datepicker__close-icon {
        right: 37px;
      }

      input {
        font-size: 14px;
        color: $dt-darkBlue;
      }
    }
  }

  &::after {
    position: absolute;
    pointer-events: none;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    color: $dt-darkBlue;
    font-family: "FontAwesome";
    content: "\f073";
    transform: translateY(calc(-50% - 2px));
  }
}