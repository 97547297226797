#NotificationScheduler .content {
  background-color: #EDF0F4;
  padding: 20px;
  border-radius: 4px;
  border: 1px solid #E1E6ED
}

#NotificationScheduler .content>div {
  margin-bottom: 10px
}

#NotificationScheduler .content>div:last-child {
  margin-bottom: unset
}

#NotificationScheduler .content .rowTitle {
  font-weight: bold
}

#NotificationScheduler .content .flexRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

#NotificationScheduler .content .flexRow input,
#NotificationScheduler .content .flexRow select {
  margin-left: 10px
}

#NotificationScheduler .content .flexRow .iconLabel {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  position: relative
}

#NotificationScheduler .content .flexRow .iconLabel.withArticle input {
  padding-right: 25px;
  text-overflow: ellipsis
}

#NotificationScheduler .content .flexRow .react-datepicker-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-left: 10px
}

#NotificationScheduler .content .flexRow .react-datepicker-wrapper>div {
  width: 100%
}

#NotificationScheduler .content .flexRow .react-datepicker-wrapper>div>input {
  margin: 0
}

#NotificationScheduler .content .flexRow .react-datepicker-wrapper .react-datepicker__close-icon {
  right: 37px
}

#NotificationScheduler .content .messageTextarea {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: none;
  resize: none;
  color: #002e67
}

#NotificationScheduler .content .rowInfo {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right
}

#NotificationScheduler input,
#NotificationScheduler select,
#NotificationScheduler textarea {
  color: #002e67;
  font-size: 12px
}

#NotificationScheduler .buttonContainer {
  margin-top: 20px
}