@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#WidgetManager {
  position: relative;
  margin-top: 20px;
  min-height: 100px;
}

#WidgetManager #pageDropDown {
  text-transform: unset
}

#WidgetManager .navigationLink {
  color: #002e67 !important
}

#WidgetManager .navigationLink .material-icons {
  vertical-align: middle
}

.modal-title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.modal-title .headerTitle {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #002e67;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold
}

.modal-title .close:hover {
  cursor: pointer
}