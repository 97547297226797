@import 'client/colors';


.NcSlideConfigMediaSelect {
  :global {
    #MediaSelector {
      position: fixed;
    }
  }
}

.mediaSelectButtonWrapper {
  width: 100%;

  i {
    font-size: 18px;
    color: $errorRed;
    cursor: pointer;
  }
}

.mediaSelectButton {
  width: 100%;
}