@import 'client/colors';

.NewsletterPreviewModal {
  color: inherit;

}

.iframeContainer {
  height: 100%;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 20%), 0 2px 4px 0 rgb(0 0 0 / 20%);
  max-width: 700px;
  margin: auto;
  background-color: white;
  padding: 20px 0;

  iframe {
    height: 100%;
  }
}