@import "client/colors";

.keyTypeSelect {
  display: flex;
  align-items: center;
}

.selectBox {
  &:disabled {
    background-color: #ffffff;
    opacity: 0.6;
  }
}

.infoIcon {
  margin-left: 5px;
  font-size: 18px;
  color: $dt-button-grey;
  cursor: default;
}