$dt-blue: #009BC2;
$dt-orange: #ee7d11;
$dt-green: #96BF0D;
$dt-violet: #AB0076;

$dt-background: #F6F6F6;
$dt-border: #E5E5E5;
$dt-header-background: #EDF0F4;
$dt-header-border: #E1E6ED;

$dt-button-grey: #9faaae;
$dt-button-grey-hover: #6f777a;
$dt-button-border: #ced4da;

$dt-darkBlue: #002e67;
$dt-darkBlue-hover: #002048;

$dt-black: #444444;

$errorRed: #B00020;

$dt-toggle-green: #63bd04;

// $dt-background-grey: $dt-grey-1;
// $dt-border: $dt-grey-2;
// $dt-button-grey: $dt-grey-3;


$dt-primary: #0275d8;

$dt-grey-3: $dt-button-grey;