@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#CommentsAccordion {
  padding: 0 15px;
  height: 100%
}

#CommentsAccordion .accordion-header {
  color: #002e67;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin-bottom: 10px;
  height: 60px;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  cursor: pointer
}

#CommentsAccordion .accordion-body {
  height: calc(100% - 80px);
  overflow: auto;
  overflow: overlay
}

#CommentsAccordion .accordion-body::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#CommentsAccordion .accordion-body::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#CommentsAccordion .accordion-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#CommentsAccordion .accordion-body {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#CommentsAccordion .accordion-body:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#CommentsAccordion .accordion-body:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#CommentsAccordion .accordion-body:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#CommentsAccordion .accordion-body:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}