@import 'client/colors';

.NcSettingsImageSelect {
  display: flex;
  flex-direction: column;
  gap: 16px;

  :global {
    #MediaSelector {
      position: fixed;
    }
  }
}

.mediaSelectButtonWrapper {
  width: 200px;
  display: flex;
  align-items: center;
  gap: 16px;

  i {
    font-size: 18px;
    color: $errorRed;
    cursor: pointer;
  }
}

.mediaSelectButton {
  width: 200px !important;
}

.text {
  color: inherit;
}

.imagePreview {
  // margin-top: 10px;
  background-position: center;
  background-size: contain, cover;
  background-repeat: no-repeat;
  border: 1px solid $dt-border;
  border-radius: 8px;
  min-height: 120px;
  width: 200px;
  position: relative;

  span {
    position: absolute;
    top: 5px;
    right: 5px;
    color: $errorRed;
    cursor: pointer;
  }
}

.withError {
  border: 1px solid $errorRed !important;
}

.extraText {
  font-size: 12px;
  color: $dt-button-grey;
  text-transform: none;
}

.errorMsg {
  color: $errorRed;
  font-size: 14px;
  margin-top: 10px;
}