@import 'client/colors';

.NcSettingsGalleryItem {
  position: relative;

  .onlyOnHover {
    display: none;
  }

  &:hover {
    .onlyOnHover {
      display: flex;
    }
  }
}

.img {
  border-radius: 8px;
  width: 232px;
  height: 135px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.fullName {
  margin-top: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}

.actionIcon {
  width: 32px;
  height: 32px;
  border-radius: 4px;
  background-color: #ffffff;
  cursor: pointer;
  position: absolute;
  top: 8px;
  justify-content: center;
  align-items: center;
  display: flex;
}


.deleteIcon {
  color: $errorRed;
  right: 8px;
}

.downloadIcon {
  left: 8px;
}

.copyUrlIcon {
  left: 48px;
}