@import "client/colors";
@import "client/mixins";
@import "client/typography";

.folder {
  display: flex;
  padding: 8px;
  align-items: center;
  gap: 8px;
  flex: 0 0 220px;
  border-radius: 4px;
  border: 0px;
  cursor: pointer;
  background: transparent;
  &:hover{
    background: $dt-header-background;
  }
}

.iconWrapper {
  position: relative;
  width: 48px;
  height: 48px;
}

.counter {
  position: absolute;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 10px;

  color: white;
}

.label {
  color: $dt-black;
}

.textBold {
  @include boldFont;
}