@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#NotificationPreview {
  padding: 0px 20px;
  font-size: 14px;
  display: flex;
  height: 100%;
  flex-direction: column
}

#NotificationPreview .headerDiv {
  color: #002e67;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  padding: 30px 0 10px 0
}

#NotificationPreview>.content {
  margin-top: 10px;
  font-size: 14px;
  flex: 1;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: 550px;
  position: relative
}

#NotificationPreview .notificationPreviewContainer {
  padding: 20px;
  background-color: #ffffff;
  min-height: 100px;
  border-radius: 10px;
  position: absolute;
  top: 150px;
  left: 50%;
  transform: translateX(-50%);
  width: 350px
}

#NotificationPreview .notificationPreviewContainer .header {
  display: flex;
  align-items: center
}

#NotificationPreview .notificationPreviewContainer .header .appLogo {
  margin-right: 10px
}

#NotificationPreview .notificationPreviewContainer .header .appLogo img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  border-radius: 10px
}

#NotificationPreview .notificationPreviewContainer .header .appName {
  flex: 1;
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.8px;
  color: #002e67;
  text-transform: uppercase
}

#NotificationPreview .notificationPreviewContainer .content {
  margin-top: 10px;
  font-size: 18px
}