@import "client/colors";

/* some manipulation to make the new datatable looking the same as with the old react bootstrap table, as we are in overview */
.TopChannels {
  :global {
    .tableDiv {
      border: 1px solid $dt-border;
      border-radius: 4px;
      overflow: hidden;

      .table {
        margin-bottom: 0;

        thead tr {
          height: 48px;
        }

        thead,
        tbody {
          border: 0px;
        }
      }

      .tableFooterDiv {
        border: none;
      }
    }


  }
}