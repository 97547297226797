.main {
  margin: 2.5vh auto !important;
}

.scrollable {
  :global(.modal-content) {
    max-height: 95vh;
  }

  :global(.modal-body) {
    overflow: auto;
  }
}

.fullHeight {
  :global(.modal-content) {
    height: 95vh;
  }
  :global(.modal-body) {
    max-height: 100%;
  }
}

.xxl {
  max-width: 1600px !important;
}