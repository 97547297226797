@import "client/colors";

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.UploadProgressBar {
  color: inherit;
  width: 100%;

  :global {
    .progress-bar {
      background-color: $dt-darkBlue;
    }
  }

  .uploadingText {
    text-align: center;
    animation: blinker 1s linear infinite;
    color: $dt-darkBlue;
    // font-style: italic;
  }
}