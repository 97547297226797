@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#TargetingWidget .flexRow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

#TargetingWidget .flexRow .rowInfo {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: right
}