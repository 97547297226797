@import 'client/colors';
@import 'client/mixins';

.CmsTags {
  color: inherit;
}

.existingTagsContainer {
  max-height: 150px;
  // flex:1 0 0;
  @include edScrollbar;
  overflow-x: hidden;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  :global {
    .badge {
      margin: 2px;
      background: transparent;
      color: $dt-grey-3;
      border: 1px solid $dt-grey-3;
      cursor: pointer;

      &.active {
        background: $dt-darkBlue;
        color: white;
        border: 1px solid transparent;
      }
    }
  }

}