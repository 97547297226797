@import "client/colors";

.MediaSelectButton {
  position: relative;

  input:not([disabled]) {
    color: $dt-darkBlue;
    background-color: white !important;
    cursor: pointer;
  }

  &::after {
    position: absolute;
    content: "\f15b";
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "FontAwesome";
    font-weight: normal;
    font-size: 14px;
    color: $dt-darkBlue;
    margin-right: 5px;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &[data-media-type="images"]::after {
    content: "\f03e";
  }

  &[data-media-type="videos"]::after {
    content: "\f008";
  }

  &[data-media-type="audios"]::after {
    content: "\f001";
  }
}