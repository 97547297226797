@import 'client/colors';

.NcSettingsConfigurationRow {
  display: flex;
  gap: 16px;
  font-size: 14px;
  align-items: center;

  &:not(:first-child) {
    margin-top: 16px;
  }

  &>div:first-child {
    flex: 1;
    text-align: right;
    font-weight: bold;
  }

  &>div:last-child {
    flex: 3;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 16px;

    input {
      width: 120px;
    }

    input[type="number"] {
      width: 80px;
    }
  }
}

.imageSelectRow {
  align-self: flex-start;
  line-height: 38px;
}