@import 'client/colors';

.CmsVariationDetails {
  color: inherit;

  :global {

    label {
      font-weight: bold;
    }
  }
}

.subheader {
  display: flex;
  align-items: center;
  background-color: white;
  padding: 16px;
  gap: 20px;
}


.titleInput {
  height: 48px;
  text-align: center;
  // font-size: 20px;
  // font-weight: bold;
}

.draftBadge {
  background-color: $dt-orange !important;
  text-transform: uppercase;
  padding: 4px 8px !important;
  border-radius: 4px !important;
  margin-right: 16px;
}

.deleteButton {
  text-transform: uppercase;
  font-weight: bold !important;
  font-size: 14px !important;
  letter-spacing: 0.5px;
}