@import 'client/colors';

.Newsletters {
  margin-top: 20px;

  :global {
    i.status {
      font-size: 10px;

      &.status-sent,
      &.status-ready {
        color: $dt-green;
      }

      &.status-manuallySent {
        color: $dt-blue;
      }
    }
  }
}

.legacyRow {
  opacity: 0.6;
}