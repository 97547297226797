@import 'client/colors';


.CmsFeaturedImage {
  color: inherit;
}

.wrapper {
  position: relative;

  &::after {
    color: $dt-darkBlue;
    content: "\f03e";
    font-family: FontAwesome;
    pointer-events: none;
    position: absolute;
    right: 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}

.inputButton {
  background-color: #fff !important;
  color: $dt-darkBlue;
  cursor: pointer;
  font-size: 14px;

  &[readonly] {
    background-color: #fff;
  }
}

.emptyIcon {
  color: red;
  cursor: pointer;
  font-size: 18px;
  position: absolute;
  right: 35px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.featuredImage {
  margin-top: 20px;
  position: relative;
  text-align: center;
  width: 100%;

  img {
    height: 100%;
    max-width: 100%;
    border-radius: 5px;

  }
}

.empty {
  border: 1px solid $dt-button-grey;
  height: 150px;
  border-radius: 5px;
}