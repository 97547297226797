@import "client/colors";

.CmsOverview {
  margin-top: 20px;

  :global {
    .status {
      font-size: 12px;

      &.status-visible {
        color: $dt-green;
      }
    }
  }
}

.statusFilters {
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
  color: #444;
}

.showOption {
  cursor: pointer;
  text-decoration: underline;
  color: $dt-darkBlue;
}

.showSelectedOption {
  font-weight: 700;
}

.filterSeparator {
  margin: 0 5px;
}

//important is needed for overriding the .btn of reactstap
.deleteButton {
  background-color: $errorRed !important;
  color: #fff !important;
  border-radius: 4px !important;
  border-color: $errorRed !important;
  box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, .2) !important;
  font-size: 10px !important;
  font-weight: bold !important;
  text-transform: uppercase !important;
  letter-spacing: .5px !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: normal !important;
  height: 24px;
  border: 0;

  &:hover {
    color: #fff;
    filter: brightness(85%);
  }
}

.titleWrapper {
  position: relative;
  display: flex;
  align-items: center;

  .langBadge {
    padding: 5px;

    &.empty {
      font-style: italic;
    }
  }
}