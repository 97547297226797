@import 'client/colors';

.NewsletterContentArticle {
  background-color: white;
  padding: 20px;
  border: 1px solid $dt-button-grey;
  position: relative;
  // text-align: left;
}

.metaInfo {
  position: absolute;
  right: 5px;
  top: 5px;
  // transform: translate(-5px,-40px);
  color: silver;
  font-size: 12px;
}