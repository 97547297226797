@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#Main {
  height: 100vh;
  overflow: auto;
  overflow: overlay;
  scrollbar-width: thin !important;
  margin-left: 120px;
  width: calc(100% - 120px)
}

#Main::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#Main::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#Main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#Main {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#Main:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#Main:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#Main:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#Main:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#Main #Page {
  min-width: 894px;
  padding: 40px 20px;
  min-height: calc(100vh - 40px)
}

@media (min-width: 1200px) {
  #Main #Page {
    padding: 40px
  }
}