@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#ActivePeriodSelector {
  display: inline-block
}

#ActivePeriodSelector .dropdown-toggle {
  line-height: normal !important;
  height: 36px;
  width: 180px;
  margin-left: 50px;
  color: #002e67 !important;
  font-size: 12px !important;
  background-color: #ffffff !important;
  font-weight: normal !important;
  text-transform: none !important;
  text-align: left !important
}

#ActivePeriodSelector .dropdown-toggle:hover {
  background-color: #ffffff !important
}

#ActivePeriodSelector .dropdown-toggle::after {
  position: absolute;
  right: 5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-family: 'FontAwesome';
  content: '\f107';
  border: 0 !important
}

#ActivePeriodSelector .dropdown-toggle.smallSelect {
  height: 25px;
  font-size: 10px !important;
  border: 1px solid !important;
  border-color: #ced4da !important;
  margin-left: 10px;
  -webkit-box-shadow: none !important;
  box-shadow: none !important
}

#ActivePeriodSelector .dropdown-menu {
  font-size: 12px !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  min-width: 265px !important;
  text-align: left !important
}

#ActivePeriodSelector .dropdown-menu:focus {
  outline: none !important
}

#ActivePeriodSelector .dropdown-divider {
  margin: 0 !important
}

#ActivePeriodSelector .periodGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

#ActivePeriodSelector .periodGroup .mainSection {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 15px
}

#ActivePeriodSelector .periodGroup .mainSection div {
  text-align: left
}

#ActivePeriodSelector .periodGroup .mainSection .groupHeader {
  text-transform: uppercase;
  color: #002e67;
  font-weight: bold;
  margin-bottom: 5px
}

#ActivePeriodSelector .periodGroup .mainSection .groupFilters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left
}

#ActivePeriodSelector .periodGroup .mainSection .groupFilters .groupFilter {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

#ActivePeriodSelector .periodGroup .mainSection .groupFilters .groupFilter:hover:not(.active) {
  cursor: pointer;
  text-decoration: underline
}

#ActivePeriodSelector .periodGroup .mainSection .groupFilters .groupFilter.active {
  color: #ee7d11;
  font-weight: bold
}

#ActivePeriodSelector .periodGroup .iconSection {
  width: 65px;
  border-left: 1px solid #E5E5E5;
  position: relative;
  color: #9faaae
}

#ActivePeriodSelector .periodGroup .iconSection.active,
#ActivePeriodSelector .periodGroup .iconSection.opened {
  background-color: #F6F6F6;
  position: relative
}

#ActivePeriodSelector .periodGroup .iconSection.active::before,
#ActivePeriodSelector .periodGroup .iconSection.opened::before {
  content: "";
  position: absolute;
  top: 50%;
  border: 8px solid transparent;
  left: -0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-left: 8px solid #E5E5E5
}

#ActivePeriodSelector .periodGroup .iconSection.active::after,
#ActivePeriodSelector .periodGroup .iconSection.opened::after {
  content: "";
  position: absolute;
  top: 50%;
  border: 8px solid transparent;
  left: -1px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  border-left: 8px solid #ffffff
}

#ActivePeriodSelector .periodGroup .iconSection.active {
  color: #ee7d11
}

#ActivePeriodSelector .periodGroup .iconSection .react-datepicker-wrapper {
  width: 100%;
  height: 100%
}

#ActivePeriodSelector .periodGroup .iconSection .react-datepicker-wrapper .react-datepicker__input-container {
  width: 100%;
  height: 100%
}

#ActivePeriodSelector .periodGroup .iconSection .react-datepicker-wrapper .react-datepicker__input-container .customPeriodPickerToggle {
  width: 100%;
  height: 100%;
  position: relative;
  cursor: pointer
}

#ActivePeriodSelector .periodGroup .iconSection .react-datepicker-wrapper .react-datepicker__input-container .customPeriodPickerToggle i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

#ActivePeriodSelector .periodGroup .iconSection .react-datepicker {
  left: 3px !important
}

#ActivePeriodSelector .periodGroup .iconSection .react-datepicker-popper.monthly {
  width: 220px
}

#ActivePeriodSelector .periodGroup .iconSection .react-datepicker-popper.yearly {
  width: 160px
}

#ActivePeriodSelector .periodGroup .iconSection .react-datepicker-popper.weekly .react-datepicker__week:hover .react-datepicker__day:not(:nth-child(2)) {
  border-radius: 0.3rem;
  background-color: #f0f0f0
}

#ActivePeriodSelector .periodGroup .iconSection .react-datepicker-popper.weekly .react-datepicker__week:hover+.react-datepicker__week .react-datepicker__day:nth-child(2) {
  border-radius: 0.3rem;
  background-color: #f0f0f0
}

#ActivePeriodSelector .periodGroup .iconSection .react-datepicker-popper.weekly .react-datepicker__week:hover .react-datepicker__week-number {
  color: #444;
  cursor: pointer
}

#ActivePeriodSelector .periodGroup .iconSection .react-datepicker-popper.weekly .react-datepicker__day:nth-child(2):hover {
  background-color: transparent !important
}

#ActivePeriodSelector .periodGroup .iconSection .react-datepicker-popper.weekly .react-datepicker__week.selected .react-datepicker__week-number {
  border: 1px solid #002e67;
  color: #002e67
}