#NotificationInfo {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding-top: 30px;
  font-size: 12px
}

#NotificationInfo .notificationInfoTable td:first-child {
  font-size: 14px;
  font-weight: bold;
  padding-right: 20px
}

#NotificationInfo .cancelScheduling {
  margin-left: 10px
}