@import 'client/colors';
@import 'client/mixins';

.ArticleDiscussion {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.commentsContainer {
  flex: 1;
  @include edScrollbar;
}

.deleteButtonWrapper {
  position: absolute;
  left: 50%;
  bottom: 24px;
  transform: translate(-50%, -24px);
  padding: 16px 24px;
  border: 1px solid $dt-button-grey;
  border-radius: 8px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  background: $dt-background;
}