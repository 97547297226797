#QrModal {
  border: none
}

#QrModal .modal-header {
  color: #002e67
}

#QrModal .modal-header i.fa {
  font-size: 32px;
  vertical-align: middle;
  margin-right: 10px
}

#QrModal .modal-body {
  min-height: 320px;
  text-align: center
}

#QrModal .messageContainer {
  font-size: 24px
}

#QrModal .qrCodeContainer {
  min-height: 220px
}

#QrModal #qrCodeDiv {
  position: absolute;
  width: auto;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}