#TabbedChartsBox {
  position: relative;
  height: 360px !important
}

#TabbedChartsBox .chartBox {
  display: none
}

#TabbedChartsBox .chartBox.active {
  display: block
}

#TabbedChartsBox .chartBox .Charts_topLevelContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 95%
}

#TabbedChartsBox .chartBox .Charts_topLevelContainer#VisitsPerPersonDistributionNumbers {
  left: 25%;
  width: 50%
}

#TabbedChartsBox .chartBox .Charts_topLevelContainer#VisitsPerPersonDistributionPercentage {
  left: 75%;
  width: 50%
}

#TabbedChartsBox .chartBox .Charts_topLevelContainer .chart-header {
  color: #444;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  text-align: center
}

#TabbedChartsBox .chartBox .Charts_topLevelContainer .chart-body {
  height: 280px
}

#TabbedChartsBox .tabBox {
  height: 25%;
  text-align: center;
  background-color: #EDF0F4;
  position: relative;
  cursor: pointer
}

#TabbedChartsBox .tabBox:not(:last-child) {
  border-bottom: 1px solid white
}

#TabbedChartsBox .tabBox.active {
  background-color: white;
  cursor: auto
}

#TabbedChartsBox .StatBox {
  text-align: center;
  font-weight: bold;
  color: #444;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 10px
}

#TabbedChartsBox .StatBox .valueDiv {
  font-size: 20px !important;
  letter-spacing: 0.3px;
  color: #002e67;
  white-space: nowrap
}

#TabbedChartsBox .StatBox .textDiv {
  font-size: 10px;
  letter-spacing: 1px;
  text-transform: uppercase
}