@import "client/colors";

.ChangelogModal {
  color: inherit;
}

.changelogWrapper {
  padding: 48px !important;

  blockquote {
    border-left: 4px solid dodgerblue;
    padding-left: 8px;
    strong {
      display: block;
      color: dodgerblue;
    }
  }

  h2 {
    margin: 48px 0 24px 0;
  }

  h3 {
    margin: 32px 0 16px 0;
  }

  :global {
    #unreleased {
      display: none;
    }
  }
  li a {
    cursor: default;
    pointer-events: none;
    color: inherit;
  }
  // li {
  //   position: relative;
  //   width: fit-content;
  //   &::after {
  //     position: absolute;
  //     top: 50%;
  //     right: 0%;
  //     content: "";
  //     transform: translate(0px, -50%);
  //     background-color: $dt-background;
  //     width: 12px;
  //     height: 20px;
  //   }
  //   a {
  //     display: none;
  //   }
  // }
}
