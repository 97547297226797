@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#SurveySectionList .elementBox {
  min-height: auto;
  margin-top: 20px
}

#SurveySectionList .elementBox:hover {
  border-color: #ee7d11;
  cursor: pointer
}

#SurveySectionList .elementBox.active {
  border-color: #ee7d11
}

#SurveySectionList .iconsDiv {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

#SurveySectionList .iconsDiv .spacer {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

#SurveySectionList .sectionTitle {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  margin-top: 10px
}

#SurveySectionList .addNewSectionButtonDiv {
  margin: 10px 0
}

#SurveySectionList .addNewSectionButtonDiv .addNewSectionButton {
  width: 100%
}