#MonitoringOverview {
  min-height: 200px;
  position: relative
}

#MonitoringOverview .check {
  width: 100%;
  height: 100%;
  min-height: 300px;
  padding: 10px;
  border: 1px solid #9faaae;
  border-radius: 10px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 20px;
  position: relative
}

#MonitoringOverview .check .heading {
  font-size: 18px;
  font-weight: bold;
  color: #002e67
}

#MonitoringOverview .check .explanationIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #9faaae;
  cursor: pointer
}

#MonitoringOverview .check .iconContainer {
  padding: 10px
}

#MonitoringOverview .check .iconContainer .icon {
  font-size: 64px;
  vertical-align: middle
}

#MonitoringOverview .check .iconContainer .icon.icon-success {
  color: #96BF0D
}

#MonitoringOverview .check .iconContainer .icon.icon-error {
  color: #B00020
}

#MonitoringOverview .check .result {
  font-size: 24px;
  font-weight: bold
}

#MonitoringOverview .check .moreContainer {
  margin-top: 10px;
  margin-bottom: 0px;
  overflow: auto;
  max-height: 150px;
  text-align: left
}

#MonitoringOverview .check .openSuggestionsContainer {
  text-align: right
}

#MonitoringOverview .check .openSuggestionsContainer .openSuggestions {
  color: #444;
  font-size: 14px;
  text-decoration: underline;
  cursor: pointer
}

#MonitoringOverview .check .footer {
  position: absolute;
  bottom: 0;
  font-style: italic
}

.suggestionAlert {
  font-size: 16px
}