@import 'client/colors';

.SourceInComposedItem {
  position: relative;

  i {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    font-size: 14px;
    cursor: pointer;
    color: $errorRed;
  }
}

.notFoundDetails {
  cursor: help;
}