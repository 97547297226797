@import 'client/colors';

.NewsletterBuilder {
  padding: 20px;
}

.wrapper {
  min-height: 100px;
  width: 680px;
  margin: auto;
  padding: 0 20px;
}