@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#Footer {
  height: 40px;
  line-height: 40px;
  text-align: center;
  color: #9faaae;
  font-size: 12px
}