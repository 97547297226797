@import "client/colors";

.toolbar {
    margin-bottom: 20px;

    > :last-child {
        text-align: right;
    }

    input,
    select {
        border-color: $dt-border;
    }

    .buttonsWrapper {
        display: flex;

        button {
            margin-left: 10px;
        }
    }

    .spacer {
        flex: 1;
    }

    .paginationTotal {
        align-self: center;
    }

    .searchWrapper {
        margin-bottom: 20px;
        display: flex;
        align-items: center;

        >div:not(:last-child) {
            margin-right: 15px;
        }

        :global .react-toggle {
            margin-left: 5px;
        }
    }

    .anonymousToggleContainer {
        display: flex;
        align-items: center;
    }

    .searchBarContainer {
        flex: 1;
        display: flex;

        label {
            display: block;
            margin: 0;
        }

        input {
            width: 100%;
        }
    }

    .datePickerWrapper {
        position: relative;

        &::after {
            position: absolute;
            pointer-events: none;
            right: 10px;
            top: 50%;
            transform: translateY(-50%);
            color: $dt-darkBlue;
            font-family: "FontAwesome";
            content: "\f073";
            height: 28px;
        }

        :global {
            .react-datepicker-wrapper {
                width: 100%;

                .react-datepicker__input-container {
                    width: 100%;

                    .form-control.is-invalid {
                        background-image: none;
                    }
                }

                .react-datepicker__close-icon {
                    right: 37px;
                }

                // input {
                //     // font-size: 14px;
                //     // color: $dt-darkBlue;
                // }
            }

            .react-datepicker-popper {
                z-index: 2;
            }
        }
    }
}

.exportProgressBarWrapper {

    // display: inline;
    :global {
        .CircularProgressbar {
            height: 48px;
            width: 48px;
        }
    }
}