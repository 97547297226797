@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#ArticleDetails {
  background: #ffffff;
  -webkit-box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;
  width: calc(100% - 140px);
  left: 140px;
  z-index: 3
}

#ArticleDetails>.header {
  padding: 20px;
  border-bottom: 1px solid #E5E5E5;
  position: relative;
  -webkit-box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1)
}

#ArticleDetails>.header .closeIcon {
  cursor: pointer;
  z-index: 2
}

#ArticleDetails>.header .headerTitle {
  position: absolute;
  left: 25%;
  -webkit-transform: translateX(-25%);
  transform: translateX(-25%);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: #002e67;
  line-height: 48px
}

#ArticleDetails>.content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0;
  padding-bottom: 20px;
  overflow: hidden;
  height: calc(100% - 65px)
}

#ArticleDetails>.content.simple {
  overflow: auto;
  overflow: overlay;
  padding: 20px;
  height: calc(100% - 85px);
  background: white;
  position: relative
}

#ArticleDetails>.content.simple::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#ArticleDetails>.content.simple::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#ArticleDetails>.content.simple::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#ArticleDetails>.content.simple {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#ArticleDetails>.content.simple:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#ArticleDetails>.content.simple:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#ArticleDetails>.content.simple:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#ArticleDetails>.content.simple:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#ArticleDetails>.content .leftPart {
  position: relative;
  background: white;
  -webkit-box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1)
}

#ArticleDetails>.content .rightPart {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  overflow: hidden;
  background: #F6F6F6
}

#ArticleDetails>.content .rightPart .nonCollapsible {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow: auto;
  overflow: overlay
}

#ArticleDetails>.content .rightPart .nonCollapsible::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#ArticleDetails>.content .rightPart .nonCollapsible::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#ArticleDetails>.content .rightPart .nonCollapsible::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#ArticleDetails>.content .rightPart .nonCollapsible {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#ArticleDetails>.content .rightPart .nonCollapsible:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#ArticleDetails>.content .rightPart .nonCollapsible:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#ArticleDetails>.content .rightPart .nonCollapsible:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#ArticleDetails>.content .rightPart .nonCollapsible:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#ArticleDetails>.content .rightPart .collapsible {
  background: white;
  position: relative;
  -webkit-box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  border-left: 1px solid #E5E5E5
}

#ArticleDetails .loaderContainer {
  position: relative;
  min-height: 285px;
  border: 1px solid #E5E5E5;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: none
}

#ArticleDetails .loaderWrapper {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center
}

#ArticleDetails .articleContentContainer {
  border-left: 1px solid #f3f6f7;
  background: #fcfdfe;
  -webkit-box-shadow: inset 2px 0 10px 0 rgba(0, 0, 0, 0.1);
  box-shadow: inset 2px 0 10px 0 rgba(0, 0, 0, 0.1)
}

#ArticleDetails .articleStatsAndInfoContainer {
  padding: 0
}