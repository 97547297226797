@import 'client/colors';

.tileWrapper {
  display: flex;
  gap: 24px;
}

.tile {
  flex: 1;
  display: flex;
  flex-direction: column;
  border: 1px solid $dt-button-grey;
  padding: 16px 8px 32px 8px;
  border-radius: 4px;
  background-color: #ffffff;

  &:hover {
    border-color: $dt-orange;
    cursor: pointer;
  }
}

.tileIcon {
  border: 2px solid $dt-border;
  border-radius: 4px;
  padding: 16px;
  text-align: center;

  img {
    width: 48px;
    height: 48px;
  }
}

.tileTitle {
  color: $dt-darkBlue;
  font-size: 20px;
  text-transform: capitalize;
  font-weight: bold;
  padding: 8px;
}