@import "client/_colors";

.badgeContainer {
    position: relative;
    top: -2px;
    left: 14px;
}

.tabContainer {
    position: relative;
}

.iconWithBadgeWrapper {
    position: absolute;
    margin-left: 5px;
    display: inline;
    

    .iconWithBadge {
        position: relative;
        color: $dt-orange;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $dt-orange;
        bottom: 2px;
        padding: 4px;
        border-radius: 50%;

        &.success {
            color: $dt-green;
            border-color: $dt-green;
        }

        &.invertedColors {
            color: white;
            border-color: white;
        }

        .iconBadge {
            position: absolute;
            top: -8px;
            right: -8px;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: red;
            color: #ffffff;
            font-size: 8px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.invertedColors {
                background: black;
            }
            
        
            .badgeCount {
                padding: 2px;
            }
        }
    }
}
