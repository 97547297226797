@import "client/colors";

.CmsAccordionManager {
    border: none;

    :global {
        .modal-header {
            color: $dt-darkBlue;

            img {
                font-size: 32px;
                vertical-align: middle;
                margin-right: 10px;
            }
        }

        .modal-body {
            min-height: 320px;
            text-align: center;
        }

        .cmsPanel {
            margin-bottom: 10px;

            .toast {
                max-width: unset;

                .toast-header {
                    padding-top: 10px;
                    padding-bottom: 10px;

                    .mr-auto {
                        width: 100%;
                    }

                    button.close {
                        margin-left: 10px;
                    }
                }
            }
        }
    }
}
