@import "../../colors";

.body {
    :global(.panel .title) {
        /*hide original titles*/
        display: none
    }
}

.panel {
    clear: both;
}

.body {
    padding: 10px;
}

.titleRow {
    cursor: pointer;

    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    display: flex;
    flex-flow: row;
    align-items: center;
    border-bottom: 1px solid;

}

.arrowIcon {
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
}


.title {
    font-weight: bold;
}