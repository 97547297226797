@import 'client/colors';

.NcSettingsConfiguration {
  font-size: 14px;
}

.sectionTitle {
  color: $dt-darkBlue;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.channelSelectWrapper {
  flex: 1;
}

.colorPickerTd {
  overflow: visible !important;
}

.colorsTableWrapper {
  :global {

    .chrome-picker {
      position: sticky;
      transform: translateX(-70px);
    }

    .table-responsive {
      overflow: visible !important;
    }
  }
}