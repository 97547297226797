@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#ArticlePerformance {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column;
  flex-flow: column;
  overflow: auto;
  overflow: overlay
}

#ArticlePerformance::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#ArticlePerformance::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#ArticlePerformance::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#ArticlePerformance {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#ArticlePerformance:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#ArticlePerformance:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#ArticlePerformance:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#ArticlePerformance:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#ArticlePerformance #StatsSection {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5 0 0px;
  flex: 1.5 0 0;
  border-bottom: 1px solid #E5E5E5;
  padding: 15px
}

#ArticlePerformance #InformationSection {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  padding: 15px
}

#ArticlePerformance #ArticleChart {
  position: relative;
  margin: 40px 0
}

#ArticlePerformance #ArticleChart .chart-header {
  color: #002e67;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin-bottom: 10px
}

#ArticlePerformance #ArticleChart .groupFilter {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 10px
}

#ArticlePerformance #ArticleChart .groupFilter select {
  font-size: 10px;
  display: inline-block;
  width: 100px;
  margin-left: 10px;
  height: 25px;
  padding: 0px 0.75rem
}

#ArticlePerformance #ArticleInfo {
  position: relative;
  height: 100%
}

#ArticlePerformance #ArticleInfo .hideArticleButton {
  width: 100%;
  text-transform: uppercase;
  background-color: #002e67;
  letter-spacing: 1px;
  min-height: 38px
}

#ArticlePerformance #ArticleInfo .header {
  color: #002e67;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin-bottom: 10px;
  height: 30px
}

#ArticlePerformance #ArticleInfo .header .fa {
  color: #9faaae
}

#ArticlePerformance #ArticleInfo .articleInfoContainer {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row
}

#ArticlePerformance #ArticleInfo .articleInfoContainer>div {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

#ArticlePerformance #ArticleInfo .articleInfoContainer .qrCodeContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center
}

#ArticlePerformance #ArticleInfo .articleInfoContainer .qrCodeContainer #qrCodeDiv {
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center
}

#ArticlePerformance #ArticleInfo .articleInfoTable {
  width: 100%
}

#ArticlePerformance #ArticleInfo .articleInfoTable td:first-child {
  font-weight: bold;
  vertical-align: top
}

#ArticlePerformance #ArticleInfo .articleInfoTable td:last-child {
  font-size: 13px
}

#ArticleComments {
  position: relative;
  height: 100%
}

#ArticleComments .deleteCommentsButton {
  position: absolute;
  top: 0;
  right: 0
}

#ArticleComments .header {
  color: #002e67;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.5px;
  font-weight: bold;
  margin-bottom: 10px;
  height: 30px
}

#ArticleComments .header .fa {
  color: #9faaae
}

#ArticleComments .content {
  height: calc(100% - 40px);
  overflow: auto;
  overflow: overlay
}

#ArticleComments .content::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#ArticleComments .content::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#ArticleComments .content::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#ArticleComments .content {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#ArticleComments .content:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#ArticleComments .content:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#ArticleComments .content:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#ArticleComments .content:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#ArticleComments .content table thead {
  display: none
}

#ArticleComments .content .text {
  text-align: left;
  white-space: normal
}

#ArticleComments .content .info {
  text-align: right;
  font-size: 13px;
  margin-top: 10px;
  white-space: normal
}

#ArticleComments .content .info .author {
  font-weight: bold
}

@media screen and (max-width: 1600px) {
  .deleteCommentsButton span {
    display: none
  }
}

_:-ms-fullscreen,
:root #StatsSection,
_:-ms-fullscreen,
:root #InformationSection {
  -ms-flex: auto !important;
  flex: auto !important
}