.image {
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: cover;
  display: block;
}

.fit {
  background-size: contain;
}

.portraitOffSet1 {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.portraitOffSet2 {
  height: 120%;
}

.full {
  width: 100%;
}