@import 'client/colors';

@mixin previewLayer {
  height: 315px;
  width: 560px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(-8px, -4px);
}

.NcSlidePreview {
  border: 8px solid #000000;
  border-radius: 8px;
  width: 560px;
  height: 315px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.previewPlaceholder {
  color: $dt-button-grey;
  font-size: 20px;
}

.newstickerPreview {
  @include previewLayer;
  background-image: url('../../previewAssets/preview-newsticker.png');
}

.typePreview {
  @include previewLayer;
  transform: translate(-8px, -8px);
}


.typePreview_image {
  background-image: url('../../previewAssets/preview-image.png');
  transform: translate(0px, 0px);
}

.typePreview_video {
  background-image: url('../../previewAssets/preview-video.png');
}

.typePreview_weather {
  background-image: url('../../previewAssets/preview-weather.png')
}

.typePreview_fullarticle {
  background-image: url('../../previewAssets/preview-full-article.png')
}

.typePreview_1article {
  background-image: url('../../previewAssets/preview-headline.png')
}

.typePreview_2articles {
  background-image: url('../../previewAssets/preview-2-headlines.png')
}