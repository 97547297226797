@import 'client/colors';

.NewsletterTemplatesetPreview {
  padding: 20px;
}

.title {
  color: $dt-darkBlue;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.wrapper {
  min-height: 100px;
  width: 680px;
  margin: auto;
  padding: 0 20px;
}