.CmsPersonalizedFeedSection {
  color: inherit;
  margin-bottom: 20px;

  :global {
    .targetInput {
      &.invalid>div:first-of-type {
        border: 1px solid red !important;
      }

      &.disabled>div:first-of-type {
        background: #fcfcfc !important;
      }

    }
  }
}