@import 'client/colors';

.NcPlaylistGroupBox {
  width: 100%;
  margin-bottom: 16px;
}

.groupTitle {
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 16px;
}