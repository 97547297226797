@import "client/colors";

.tableDiv {
    // padding-top: 20px;
    // position: relative;

    :global {
        .react-bs-container-header {
            border-radius: 4px 4px 0 0;
            background-color: $dt-header-background;
            border: 1px solid $dt-header-border;
        }

        thead {
            background-color: $dt-header-background;
            border: 1px solid $dt-header-border;
            font-size: 10px;
            color: $dt-button-grey;
            text-transform: uppercase;
            border-bottom: none;

            th {
                border-bottom: none !important;
                border-top: none;
                text-overflow: unset;
                white-space: nowrap;
                vertical-align: middle !important;

                &:focus {
                    outline: none;
                }

                &[aria-label~="sort"] {
                    position: relative;

                    span {
                        &::before {
                            position: absolute;
                            // right:5px;
                            margin-left: 5px;
                            top: 50%;
                            transform: translateY(-50%);
                            font-family: 'FontAwesome';
                            content: '\f175';
                            border: 0 !important;
                            height: 16px;
                            width: 16px;
                        }

                        &[class$="desc"]::before {
                            content: '\f176';
                            opacity: 1;
                        }

                        &::after {
                            content: '';
                            width: 100%;
                            height: 4px;
                            background-color: $dt-darkBlue;
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            transform: translateX(-50%);
                            border-radius: 2px 2px 0 0;
                            opacity: 1;
                        }
                    }
                }
            }
        }

        tbody {
            border-style: solid;
            border-color: $dt-border;
            border-width: 0px 1px;
            background-color: #ffffff;

        }

        tr {
            border-bottom: 1px solid $dt-border;
        }

        td {
            font-size: 14px;
            color: $dt-black;
            border: 0 !important;
            vertical-align: middle !important;
            white-space: nowrap;
            max-width: 300px;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        tr.disabledProfile td {
            color: $dt-button-grey;
        }

        .react-bootstrap-table-pagination {
            padding-top: 10px;
            background-color: $dt-background;

            // display: inline-flex;
            .page-item {
                margin: 0 5px;

                &:first-child {
                    margin-left: 0px;
                }

                .page-link {
                    background: none;
                    border: none;
                    color: $dt-button-grey;
                    font-size: 12px;
                    padding: 0.75rem 1rem;

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }

                &.active {
                    .page-link {
                        color: $dt-darkBlue;
                        background: $dt-header-background;
                        z-index: unset;
                    }
                }

                &[title$="page"] {
                    .page-link {
                        background: #ffffff;
                        color: $dt-darkBlue;
                        font-size: 14px;
                        border-radius: 4px;
                        box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
                        border: 1px solid transparent;

                        &:hover {
                            border-color: $dt-darkBlue;
                        }

                        &:active {
                            background: $dt-darkBlue;
                            color: white;
                        }
                    }
                }
            }

            .goToPageDiv {
                text-align: right;
                overflow: hidden;
                white-space: nowrap;

                input,
                button {
                    display: inline-block;
                    padding: 0.65rem 1rem;
                    width: auto;
                    font-size: 14px;
                    border: 1px solid $dt-border;
                    border-radius: 4px;
                }

                input {
                    width: 120px;
                    height: unset;
                }

                input:focus {
                    border-color: $dt-darkBlue;
                    color: $dt-darkBlue;
                }

                button {
                    margin-left: 20px;
                    background: #ffffff;
                    color: $dt-darkBlue;
                    font-size: 14px;
                    // box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.3);
                    border: 1px solid $dt-border;

                    &:hover {
                        border-color: $dt-darkBlue;
                    }

                    &:active {
                        background: $dt-darkBlue;
                        color: white;
                    }

                    &:focus {
                        outline: none;
                        box-shadow: none;
                    }
                }
            }
        }

        .table {
            table-layout: auto;
            border-radius: 4px 4px 0 0;
            // overflow: hidden;
        }

        .table-hover tbody tr:hover {

            // box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
            td {
                background-color: $dt-background;
            }
        }

        .actionHoveringDiv {
            .btn {
                display: none;
                white-space: nowrap;
            }
        }

        tr:hover {
            .actionHoveringDiv {
                display: block;

                .btn {
                    display: inline-block;
                }
            }

            >.hideOnHover {
                opacity: 0;
            }
        }

        .toggleWrapper {
            display: flex;
        }

    }
}

.tableOuterWrapper {
    // background-color: red;
    position: relative;

    // background-color: white;
    .tableInnerWrapper {
        //     // background-color: blue;
        overflow-x: scroll;

        //     margin-left: 120px;
        //     margin-right:50px;
        //     // width: auto;
        :global {

            //         .react-bootstrap-table-pagination{
            //             background-color: red !important;
            //         }
            //         // .table{
            //         //     // table-layout: fixed;
            //         // }
            .fixedColumn {
                position: absolute;
                position: sticky;
                position: -webkit-sticky;
                z-index: 2;
            }

            .fixed1 {
                left: 40px;
                background-color: white;
                // box-shadow: 0 0 5px rgba(0, 0, 0, 0.75);
                // clip-path: inset(1px -10px 0px 0px);

            }

            .fixedLast {
                right: 0;
                overflow: visible;
                // width:0;
            }

            .actionHoveringDiv {
                position: absolute;
                top: 50%;
                left: -100px;
                transform: translateY(-50%);
            }

            .selection-cell,
            .selection-cell-header {
                position: sticky;
                position: -webkit-sticky;
                left: 0;
                z-index: 2;

                &::after {
                    content: ' ';
                    display: inline;
                    position: absolute;
                    top: 0;
                    width: 3px;
                    height: 100%;
                    background-color: $dt-header-background;
                    right: -3px;
                }

                &::before {
                    content: ' ';
                    display: inline;
                    position: absolute;
                    top: 0;
                    width: 2px;
                    height: 100%;
                    background-color: $dt-header-border;
                    left: -2px;
                }
            }

            .selection-cell-header,
            thead .fixedColumn {
                background-color: $dt-header-background;

                &:last-child {
                    background-color: transparent;
                }
            }

            .selection-cell {
                background-color: white;
                overflow: visible;

                &::after {
                    content: ' ';
                    display: inline;
                    position: absolute;
                    top: 0;
                    width: 3px;
                    height: 100%;
                    background-color: white;
                    right: -3px;
                }

                &::before {
                    content: ' ';
                    display: inline;
                    position: absolute;
                    top: 0;
                    width: 2px;
                    height: 100%;
                    background-color: $dt-border;
                    left: -2px;
                }

                input {
                    display: flex;
                }
            }

            tbody tr:hover {
                background-color: $dt-background !important;

                .fixed1,
                .selection-cell {
                    background-color: $dt-background !important;

                    &::after {
                        background-color: $dt-background !important;
                    }
                }
            }
        }
    }
}