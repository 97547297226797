@import 'client/colors';

.ImagePlaceholder {
  background-color: $dt-border;
  border: 2px dashed $dt-button-grey;
  height: 165px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  span {
    font-size: 16px;
    color: $dt-button-grey;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.noBorder {
  border: none;
}