#Analytics_articles {
  padding-top: 20px
}

#Analytics_articles .loaderContainer {
  position: relative;
  min-height: 285px;
  border: 1px solid #E5E5E5;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: none
}

#Analytics_articles .loaderWrapper {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center
}

.PageTitle.withButton {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center
}

.PageTitle.withButton>:first-child {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}