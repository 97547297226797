@import "client/colors";


.ArticlesFilters {
  margin-bottom: 20px;

  :global {
    .toggleButton {
      background-color: white !important;
      color: $dt-darkBlue !important;
      border: 1px solid #ced4da !important;
      margin-left: 20px;
      text-transform: uppercase;
      font-size: 12px !important;

      &.active {
        background-color: $dt-header-background !important;
        border: 1px solid $dt-header-border !important;
        color: $dt-button-grey !important;
      }
    }
  }
}

.searching {
  display: flex;
}

.searchInput {
  flex: 1;
}

.filters {
  margin-top: 20px;
  background-color: $dt-header-background;
  border: 1px solid $dt-header-border;
  border-radius: 5px;
  padding: 20px;

  :global {
    label {
      font-weight: bold;
    }
  }
}

.dateFieldWrapper {
  display: flex;
  align-items: center;
  position: relative;

  &::after {
    position: absolute;
    pointer-events: none;
    right: 10px;
    top: 50%;
    transform: translateY(calc(-50% - 2px));
    color: $dt-darkBlue;
    font-family: 'FontAwesome';
    content: '\f073'
  }

  :global {
    .react-datepicker-wrapper {
      flex: 1;
    }

    .react-datepicker__close-icon {
      right: 37px;
    }
  }
}