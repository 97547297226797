@import "../../../../../colors";

.toggle {
  cursor: pointer;
  position: relative;
  width: 100%;

  &::after {
    content: '\f107';
    position: absolute;
    top: 0px;
    right: 0px;
    color: $dt-darkBlue;
    font-family: 'FontAwesome';
  }

  &.opened {
    &::after {
      content: '\f106';
    }
  }
}

.dropdown {

  label,
  input[type="checkbox"] {
    cursor: pointer;
  }

}