@import 'client/colors';

.longText {
  padding: 32px 16px;
}

.smallText {
  font-size: 14px;
}

.mediumText {
  font-size: 18px;
}

.largeText {
  font-size: 24px;
}