@import "client/colors";

.FeedSelector {
  :global {
    i.status {
      font-size: 10px;

      &.status-active {
        color: $dt-green;
      }

      &.status-inactive {
        color: #d0021b;
      }
    }

  }
}