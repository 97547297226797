@import "client/colors";
@import "client/mixins";
@import "client/typography";

.modalBody {
  padding: 0px !important;
}


.header {
  gap: 16px;
  display: flex;
  justify-content: flex-end;
  background:#FFF;
  padding: 4px 40px;
  border-bottom: 1px solid #dee2e6;
  // border-bottom: 1px solid $dt-border;


  .selectorWrapper {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  .selectorLabel {
    @include boldFont;
  }
  .selector{
    min-width: 200px;
  }
}

.content {
  padding: 24px 40px;
  background-color: $dt-background !important;
}