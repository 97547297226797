#AllArticlesStats {
  margin-top: 20px
}

#AllArticlesStats ::-webkit-input-placeholder {
  color: #9faaae;
  opacity: 1
}

#AllArticlesStats :-ms-input-placeholder {
  color: #9faaae;
  opacity: 1
}

#AllArticlesStats ::-ms-input-placeholder {
  color: #9faaae;
  opacity: 1
}

#AllArticlesStats ::placeholder {
  color: #9faaae;
  opacity: 1
}

#AllArticlesStats ::-ms-input-placeholder {
  color: #9faaae
}

#AllArticlesStats .tableDiv .currentComments {
  color: #9faaae
}