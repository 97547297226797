@import "client/colors";
@import "client/mixins";
@import "client/typography";


.breadcrumb {
  margin-bottom: 24px;
  display: flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid $dt-header-border;
  background-color: $dt-header-background;
}

.button {
  display: flex;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  margin: 0px;
  border: 0px;

  :hover {
    text-decoration: underline;
  }
}


.majorLabel {
  color: $dt-black;
  @include titleFont;

}

.minorLabel {
  color: $dt-button-grey;
  @include boldFont;
}