@import 'client/colors';

.NewsletterTemplatesetPreview_Header {
  color: inherit;
}

.headerLinks {
  background-color: $dt-border;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  span {
    font-weight: 700;
  }
}

.headerIntro {
  background-color: $dt-background;
  padding: 32px 16px;
  font-size: 18px;
}