@import "client/colors";
@import "client/mixins";
@import "client/typography";

.item {
  display: inline-block;
  position: relative;
  flex-direction: column;
  width: 244px; // 220 + 2 * padding
  padding: 12px;

  &:hover {
    .actions {
      display: flex;
    }
  }
}

.actions {
  position: absolute;
  right: 20px;
  top: 20px;
  display: none;
  align-items: center;
  gap: 8px;
}

.thumb {
  width: 220px;
  height: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 8px;
  background: #FFF;
  padding: 0px;
  border: 2px solid transparent;
  &.selectable{
    cursor: pointer;
  }
  &.selected{
    border: 2px solid $dt-orange;
  }
}

.filename {
  margin-top: 8px;
  height: 20px;
  align-self: stretch;
  color: $dt-black;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @include defaultFont;
}

.img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  object-fit: scale-down;
  display: block;
}

.button {
  position: relative;
  border: 0px;
  display: flex;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.20);
}

.copyNotification {
  color: $dt-green;
}
