@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#SelectTypeModal {
  padding: 0
}

#SelectTypeModal .modal-title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

#SelectTypeModal .modal-title .headerTitle {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #002e67;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold
}

#SelectTypeModal .modal-body {
  min-height: 100px
}

#SelectTypeModal .formTypeTile {
  height: 225px;
  border: 1px solid #9faaae;
  border-radius: 5px;
  background-color: #ffffff;
  padding: 10px;
  cursor: pointer
}

#SelectTypeModal .formTypeTile:hover {
  border-color: #ee7d11
}

#SelectTypeModal .formTypeTile .logo {
  text-align: center;
  margin-bottom: 20px
}

#SelectTypeModal .formTypeTile .header {
  color: #002e67;
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize
}

#SelectTypeModal .formTypeTile .descr {
  font-size: 14px
}