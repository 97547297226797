@import 'client/colors';
@import 'client/mixins';

.CommentDetails {
  @include fullPageModal();
}

.content {
  position: relative;
}

.header {
  display: flex;
  align-items: center;
}

.headerLabel {
  color: $dt-grey-3;
  font-size: 12px;
  font-weight: bold;
}

.headerVal {
  color: $dt-darkBlue;
  font-size: 20px;
  font-weight: bold;

  a {
    color: inherit;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
}

.idContainer {
  padding: 0px 20px;
  margin: 0 20px;
  border-width: 0 1px;
  border-style: solid;
  border-color: $dt-border;
}

.linkContainer {
  padding: 0 20px;
}

.flexSpacer {
  flex: 1;
}

.qrContainer {
  padding: 0 20px;
  display: flex;
  align-items: center;

  :global {
    .qrToggler {
      cursor: pointer;
      font-size: 32px;
      color: $dt-darkBlue;
    }
  }
}

.notFoundMessage {
  flex: 1;
  text-align: center;
  margin: 20px;
}

.articlePreviewWrapper {
  @include edScrollbar;
  padding: 20px;
  height: 100%;
}

.articlePreviewContainer {
  :global {
    #ArticleContent {
      border: 1px solid $dt-border;
    }
  }
}

.commentsSection {
  margin-bottom: 10px;
}

.sectionLabel {
  text-transform: uppercase;
  color: $dt-darkBlue;
  font-weight: bold;
  font-size: 12px;
}

.sectionHelpText {
  font-style: italic;
  color: $dt-button-grey;
  font-size: 14px;
}

.articleDiscussionWrapper {
  flex: 1;
  height: 100%;
}