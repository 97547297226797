#MonitoringDetailedTable {
  min-height: 200px;
  position: relative
}

#MonitoringDetailedTable .react-bs-table-tool-bar i {
  cursor: pointer
}

#MonitoringDetailedTable .react-bs-table-tool-bar i:hover {
  color: #ee7d11
}

#MonitoringDetailedTable .tableDiv .table thead .sort-column span.fa:not(.order)::after {
  width: 100% !important
}

#MonitoringDetailedTable .react-bs-table .alert {
  margin-bottom: 0;
  padding: 0 10px;
  overflow: hidden;
  text-overflow: ellipsis
}