@mixin fullPageModal() {
  background: #ffffff;
  // padding:20px;
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  // left: 100vw ;
  // border-left: 15px solid rgba(0, 0, 0, 0.1);
  height: 100%;
  overflow: hidden;
  width: calc(100% - 140px);
  left: 140px;
  // -webkit-animation: slide 0.75s forwards;
  // animation: slide 0.75s forwards;
  z-index: 3;

  &>.header {
    padding: 20px;
    border-bottom: 1px solid $dt-border;
    position: relative;
    box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);

    .closeIcon {
      cursor: pointer;
      z-index: 2;
    }

    .headerTitle {
      position: absolute;
      left: 25%;
      transform: translateX(-25%);
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      letter-spacing: 0.8px;
      color: $dt-darkBlue;
      line-height: 48px;
    }
  }

  &>.content {
    display: flex;
    padding: 0;
    padding-bottom: 20px;
    overflow: hidden;
    height: calc(100% - 65px);

    &.simple {
      @include edScrollbar;
      padding: 20px;
      height: calc(100% - 85px);
      background: white;
      position: relative;
    }

    .leftPart {
      position: relative;
      background: white;
      box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1);
    }

    .rightPart {
      flex: 1;
      display: flex;
      flex-direction: column;
      overflow: hidden;
      background: $dt-background;

      .nonCollapsible {
        flex: 1;
        @include edScrollbar;
      }

      .collapsible {
        background: white;
        position: relative;
        box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
        border-left: 1px solid $dt-border;
      }
    }
  }

  .loaderContainer {
    position: relative;
    min-height: 285px;
    border: 1px solid $dt-border;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    border-bottom: none;
  }

  .loaderWrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  }
}

@mixin titleDiv() {
  color: $dt-darkBlue;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
}

@mixin secondaryButtons() {
  .btn-secondary {
    font-size: 10px;
    font-weight: bold;
    border: 0;
    padding: 7px 20px;
    border-radius: 4px;
    line-height: normal;
    box-shadow: 1px 1px 2px 0 rgba(0, 0, 0, 0.1);
    height: 24px;
    text-transform: uppercase;
    background-color: #9faaae;
  }
}

@-webkit-keyframes slide {
  100% {
    left: 15px;
  }
}

@keyframes slide {
  100% {
    left: 15px;
  }
}

$breakpoint-phone: 576px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1024px;
$breakpoint-largeScreens: 1200px;

@mixin breakpoint($breakpoint) {
  @media (min-width: $breakpoint) {
    @content;
  }
}

// @mixin breakpoint-phone {
//   @include breakpoint($breakpoint-phone){
//     @content;
//   }
// }
@mixin breakpoint-tablet {
  @include breakpoint($breakpoint-tablet) {
    @content;
  }
}

@mixin breakpoint-desktop {
  @include breakpoint($breakpoint-desktop) {
    @content;
  }
}

@mixin breakpoint-largeScreens {
  @include breakpoint($breakpoint-largeScreens) {
    @content;
  }
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {

  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
    border-radius: 10px;
  }

  //For Firefox
  & {
    scrollbar-color: $foreground-color $background-color;
    scrollbar-width: thin;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

@mixin edScrollbar {

  overflow: auto;
  overflow: overlay;

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: 0 solid transparent;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px transparent;
    -webkit-box-shadow: inset 0 0 6px transparent;
  }

  & {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    scrollbar-face-color: #cdcdcd;
    scrollbar-track-color: #f7f7f7;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  &:hover {
    & {
      scrollbar-color: #cdcdcd #f7f7f7;
    }

    &::-webkit-scrollbar {
      background-color: #f7f7f7;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #cdcdcd;
      outline: 0 solid transparent;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, .05);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .05);
    }
  }
}