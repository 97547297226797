@import 'client/colors';

.ToggleButton {
  background-color: white !important;
  color: $dt-darkBlue !important;
  border: 1px solid $dt-button-border !important;
  margin-left: 20px;
  text-transform: uppercase;
  font-size: 12px !important;
  border: 0;

  &:active {
    box-shadow: none;
  }

  &.active {
    background-color: $dt-header-border !important;
    color: $dt-button-grey !important;
  }
}