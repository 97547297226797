@import 'client/colors';


.CmsAttachments {
  color: inherit;
}

.existingAttachmentsContainer {
  ul {
    margin-bottom: 0;
    padding-inline-start: 20px;
    list-style-type: square;
  }
}

.attachmentItem {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 20px;
  position: relative;
  margin-bottom: 10px;

  &>img {
    margin-right: 5px;
    width: 24px;
    height: 24px;
  }

  &>i.material-icons {
    margin-right: 5px;
    font-size: 24px;
    vertical-align: middle;
  }

  // &:hover{
  //   background-color: $dt-border;
  // }
  &>.emptyIcon {
    content: 'x';
    color: red;
    font-size: 18px;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }
}

.addItemButton {
  margin-top: 10px;
  color: $dt-darkBlue;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}