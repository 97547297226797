@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#FormDetails {
  background: #ffffff;
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  height: 100%;
  overflow: hidden;
  width: calc(100% - 140px);
  left: 140px;
  z-index: 3
}

#FormDetails>.header {
  padding: 20px;
  border-bottom: 1px solid #E5E5E5;
  position: relative;
  box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1)
}

#FormDetails>.header .closeIcon {
  cursor: pointer;
  z-index: 2
}

#FormDetails>.header .headerTitle {
  position: absolute;
  left: 25%;
  transform: translateX(-25%);
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.8px;
  color: #002e67;
  line-height: 48px
}

#FormDetails>.content {
  display: flex;
  padding: 0;
  padding-bottom: 20px;
  overflow: hidden;
  height: calc(100% - 65px)
}

#FormDetails>.content.simple {
  overflow: auto;
  overflow: overlay;
  padding: 20px;
  height: calc(100% - 85px);
  background: white;
  position: relative
}

#FormDetails>.content.simple::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#FormDetails>.content.simple::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#FormDetails>.content.simple::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#FormDetails>.content.simple {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#FormDetails>.content.simple:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#FormDetails>.content.simple:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#FormDetails>.content.simple:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#FormDetails>.content.simple:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#FormDetails>.content .leftPart {
  position: relative;
  background: white;
  box-shadow: 0px 2px 10px 0 rgba(0, 0, 0, 0.1)
}

#FormDetails>.content .rightPart {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #F6F6F6
}

#FormDetails>.content .rightPart .nonCollapsible {
  flex: 1;
  overflow: auto;
  overflow: overlay
}

#FormDetails>.content .rightPart .nonCollapsible::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#FormDetails>.content .rightPart .nonCollapsible::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#FormDetails>.content .rightPart .nonCollapsible::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#FormDetails>.content .rightPart .nonCollapsible {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#FormDetails>.content .rightPart .nonCollapsible:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#FormDetails>.content .rightPart .nonCollapsible:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#FormDetails>.content .rightPart .nonCollapsible:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#FormDetails>.content .rightPart .nonCollapsible:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#FormDetails>.content .rightPart .collapsible {
  background: white;
  position: relative;
  box-shadow: -2px 0 10px 0 rgba(0, 0, 0, 0.1);
  border-left: 1px solid #E5E5E5
}

#FormDetails .loaderContainer {
  position: relative;
  min-height: 285px;
  border: 1px solid #E5E5E5;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom: none
}

#FormDetails .loaderWrapper {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  text-align: center
}

#FormDetails>.header {
  display: flex;
  align-items: center
}

#FormDetails>.header>*:not(:first-child) {
  margin-left: 20px
}

#FormDetails>.header .btn-lg {
  font-size: inherit
}

#FormDetails>.header .react-toggle {
  vertical-align: middle
}

#FormDetails .previewContainer {
  border-left: 1px solid #f3f6f7;
  overflow: auto;
  overflow: overlay;
  padding: 20px;
  height: 100%
}

#FormDetails .previewContainer::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#FormDetails .previewContainer::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#FormDetails .previewContainer::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#FormDetails .previewContainer {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#FormDetails .previewContainer:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#FormDetails .previewContainer:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#FormDetails .previewContainer:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#FormDetails .previewContainer:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#FormDetails .formBuilderWrapper {
  padding: 20px;
  height: 100%;
  overflow: auto;
  overflow: overlay
}

#FormDetails .formBuilderWrapper::-webkit-scrollbar {
  width: 2px;
  height: 2px;
  background-color: transparent
}

#FormDetails .formBuilderWrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
  outline: 0 solid transparent
}

#FormDetails .formBuilderWrapper::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px transparent;
  -webkit-box-shadow: inset 0 0 6px transparent
}

#FormDetails .formBuilderWrapper {
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  scrollbar-face-color: #cdcdcd;
  scrollbar-track-color: #f7f7f7;
  -ms-overflow-style: -ms-autohiding-scrollbar
}

#FormDetails .formBuilderWrapper:hover {
  scrollbar-color: #cdcdcd #f7f7f7
}

#FormDetails .formBuilderWrapper:hover::-webkit-scrollbar {
  background-color: #f7f7f7
}

#FormDetails .formBuilderWrapper:hover::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  outline: 0 solid transparent
}

#FormDetails .formBuilderWrapper:hover::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.05)
}

#FormDetails .notFoundContainer {
  text-align: center;
  margin-top: 20px
}

#FormDetails .titleInput {
  height: 48px;
  text-align: center;
  font-size: 20px;
  font-weight: bold
}

#FormDetails .sourceDetailsDiv {
  margin-top: 20px;
  background-color: #E5E5E5;
  padding: 20px;
  border-radius: 5px
}

#FormDetails .sourceDetailsDiv label {
  font-weight: bold
}

#FormDetails .iconButton {
  border: 1px solid #ced4da;
  border-radius: .25rem;
  background-color: #ffffff;
  color: #002e67
}

#FormDetails .iconButton:focus {
  box-shadow: none
}

#FormDetails .iconButton.showAdvanced {
  background-color: #ee7d11;
  color: #ffffff
}

#FormDetails .iconButton i.material-icons {
  vertical-align: middle
}

#FormDetails .verticalCentered {
  display: flex;
  align-items: flex-end
}

#FormDetails .verticalCentered .react-toggle {
  vertical-align: middle
}

#FormDetails .verticalCentered .material-icons {
  vertical-align: middle
}

#FormDetails .alert {
  margin-bottom: 0;
  padding: 0 1.25rem;
  height: 38px;
  line-height: 38px
}

#FormDetails .selectedSourceContainer .alert {
  width: 100%;
  margin-bottom: 0;
  padding: 0 0.25rem;
  height: 38px;
  line-height: 38px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

#FormDetails .btn {
  text-transform: uppercase
}