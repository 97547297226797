@import "client/colors";

.ColorPicker {
  position: relative;

  input:not([disabled]) {
    background-color: white;
  }

}

.colorPreview {
  position: absolute;
  top: 50%;
  right: 10px;
  width: 16px;
  height: 16px;
  transform: translateY(-50%);
  border-radius: 4px;
  border: 1px solid $dt-button-grey;
}

.pickerWrapper {
  position: absolute;
  z-index: 2;
}

.pickerClose {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}