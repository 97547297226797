@import 'client/colors';

.NewsletterContentBuilder {
  color: inherit;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.flexRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.addRowContainer {
  background-color: $dt-header-background;
  border-radius: 8px;
  padding: 24px 16px;

  button {
    flex: 1
  }

}