@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

.usersListModal {
  padding: 0
}

.usersListModal .modal-title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex
}

.usersListModal .modal-title .headerTitle {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  color: #002e67;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold;
  font-size: 14px
}

.usersListModal .modal-title .headerTitle i {
  font-size: 24px;
  vertical-align: sub
}

.usersListModal .modal-title .close {
  cursor: pointer
}

.usersListModal .modal-body .tableDiv .react-bs-table-tool-bar {
  margin-bottom: 5px
}

.usersListModal .modal-body .tableDiv .react-bs-table-tool-bar input[type="text"] {
  border: 1px solid #9faaae
}