@import "client/colors";

.GenerateReports {
  padding-top: 20px;
  display: flex;
  gap: 30px;
}

.configuratorContainer {
  flex: 1;
}

.archiveContainer {
  flex: 3;
}

.configurator {
  background-color: $dt-header-background;
  border: 1px solid $dt-header-border;
  color: $dt-button-grey;
  padding: 20px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  gap: 20px;
}

.configuratorGroup {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.configuratorRow {
  display: flex;
  gap: 10px;
  align-items: center;

  > div {
    flex: 1;
  }
}

.actionButtons {
  margin-top: 20px;
  text-align: center;
}

.label {
  color: $dt-darkBlue;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.5px;
}

.overPeriodContainer {
  display: flex;
  align-items: center;
  gap: 8px;
  .infoIcon {
    font-size: 16px;
    cursor: default;
  }
}
