#SourcesSelector {
  margin-top: 20px
}

#SourcesSelector .titleDiv {
  color: #002e67;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: bold
}

#SourcesSelector :focus {
  outline: unset
}

#SourcesSelector .sourcesFilter {
  font-weight: normal;
  font-size: 12px
}

#SourcesSelector .sourcesFilter .separator {
  margin: 0 5px
}

#SourcesSelector .sourcesFilter .showOption {
  cursor: pointer;
  text-decoration: underline
}

#SourcesSelector .sourcesFilter .showOption.selected {
  font-weight: bold;
  text-decoration: none;
  cursor: inherit
}