#ArticleDetailsFilterBar {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row
}

#ArticleDetailsFilterBar .separator {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1
}

#ArticleDetailsFilterBar .filterWrapper img {
  display: none
}

#ArticleDetailsFilterBar .filterWrapper span {
  font-size: 10px
}

#ArticleDetailsFilterBar .filterWrapper select {
  font-size: 10px;
  height: 25px;
  padding: 0px 0.75rem
}

#ArticleDetailsFilterBar .filterSelect {
  display: inline-block;
  width: 180px;
  line-height: normal;
  height: 36px;
  margin-left: 50px;
  color: #002e67;
  font-size: 12px
}

#ArticleDetailsFilterBar .filterDropdown {
  display: inline-block
}

#ArticleDetailsFilterBar .filterDropdown .dropdown-toggle {
  line-height: normal !important;
  height: 25px;
  width: 180px;
  margin-left: 50px;
  color: #002e67 !important;
  font-size: 10px !important;
  background-color: #ffffff !important;
  font-weight: normal !important;
  text-transform: none !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  border: 1px solid !important;
  border-color: #ced4da !important
}

#ArticleDetailsFilterBar .filterDropdown .dropdown-menu {
  text-align: left
}

#ArticleDetailsFilterBar .filterDropdown .dropdown-item {
  font-size: 10px
}

#ArticleDetailsFilterBar .filterDropdown .dropdown-item label {
  margin-right: 10px
}

#ArticleDetailsFilterBar .filterDropdown .dropdown-item.active {
  background-color: #002e67
}

#ArticleDetailsFilterBar .weekPickerWrapper .react-datepicker__week:hover .react-datepicker__day:not(:nth-child(2)) {
  border-radius: 0.3rem;
  background-color: #f0f0f0
}

#ArticleDetailsFilterBar .weekPickerWrapper .react-datepicker__week:hover+.react-datepicker__week .react-datepicker__day:nth-child(2) {
  border-radius: 0.3rem;
  background-color: #f0f0f0
}

#ArticleDetailsFilterBar .weekPickerWrapper .react-datepicker__week:hover .react-datepicker__week-number {
  color: #002e67
}

#ArticleDetailsFilterBar .weekPickerWrapper .react-datepicker__day:nth-child(2):hover {
  background-color: transparent !important
}

#ArticleDetailsFilterBar .dropdown-item:active {
  background-color: transparent !important;
  color: inherit !important
}

#ArticleDetailsFilterBar .react-datepicker__input-container input {
  font-size: 10px
}