@import 'client/colors';

.NewsletterContentBuilderRow {
  padding: 32px 16px;
  display: flex;
  flex-direction: row;
  gap: 20px;
  background-color: $dt-header-background;
  border-radius: 4px;
  align-items: center;
  position: relative;
}

.flexRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.previewContainer {
  flex: 1;
  background-color: white;
  border: 1px solid $dt-button-grey;
  border-radius: 4px;
  padding: 24px 16px;
}

.actionIcon {
  cursor: pointer;
}

.rowWithErrors {
  outline: 1px solid $errorRed;
}

.errorIcon {
  color: $errorRed;
  position: absolute;
  top: 10px;
  right: 10px;
}