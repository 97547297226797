@import "../../../../colors";
@import "../../../../mixins";

.AudienceDetails {

  @include fullPageModal();


  .header {
    padding: 20px;
    border-bottom: 1px solid #e5e5e5;
    position: relative;
    box-shadow: 2px 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;

    >*:not(:first-child) {
      margin-left: 20px;
    }
  }

  .labelInput {
    height: 48px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }

  .notFoundMessage {
    flex: 1;
    text-align: center;
    margin: 20px;
  }

  .settingsContainer {
    padding: 20px;
  }

  .section {
    padding: 20px 0;
  }

  .sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
  }

  .sectionTitle {
    color: $dt-darkBlue;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
  }

  .sectionContent {
    color: inherit;
    margin-top: 20px;
    font-size: 14px;

  }

  .toggleRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .label {
    font-weight: bold;
  }
}