@import 'client/colors';

.fontsSelectLoadingWrapper {
  opacity: 0.5;
  position: relative;

  select {
    width: 240px !important;
  }

}

.loaderWrapper {
  position: absolute;
  top: 7px;
  left: 50%;
  transform: translate(-50%, 0);
}