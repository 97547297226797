@import "client/colors";
@import "client/mixins";

.groupLabel {
  @include titleDiv;
}

.showFilters {
  font-size: 12px;
  font-weight: 400;
  text-transform: none;
}

.showOption {
  cursor: pointer;
  text-decoration: underline;
  color: $dt-darkBlue;
}

.showSelectedOption {
  font-weight: 700;
  color: $dt-black;
}

.filterSeparator {
  margin: 0 5px;
}