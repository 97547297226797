@import "client/colors";

.ExtensionIcon {
 
  color: $dt-darkBlue;
}

.size24{
  font-size: 24px;
  height: 24px;
}

.size40{
  font-size: 40px;
  height: 40px;
  width: 40px;
  overflow: hidden;
  object-fit: scale-down;
  display: block;
}
