@import 'client/colors';

.NcSlideshowDetails {
  color: inherit;
}

.nameInput {
  height: 48px;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
}

.label {
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: 5px;
}

.targetingBox {
  border: 1px solid $dt-border;
  border-radius: 5px;
  min-height: 100px;
  padding: 5px;
  position: relative;
}

.targetingBoxRow {
  margin-bottom: 5px;
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;
    cursor: pointer;
  }

  input {
    cursor: pointer;
    margin-right: 5px;
  }

}