.rel {
  position: relative;
}

.abs {
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
}

.iframe {
  border-width: 0px;
  width: 100%;
  height: 100%;
  display: block
}