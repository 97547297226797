@-webkit-keyframes slide {
  100% {
    left: 15px
  }
}

@keyframes slide {
  100% {
    left: 15px
  }
}

#FilterBar {
  background-color: #002e67;
  color: #ffffff;
  padding: 20px 0;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 1px 20px 0 rgba(0, 0, 0, 0.2);
  position: -webkit-sticky;
  position: sticky;
  top: -4px;
  z-index: 2
}

@media (min-width: 1200px) {
  #FilterBar {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
    width: calc(100% + 40px)
  }
}

#FilterBar div {
  text-align: center
}

#FilterBar .verticalDevider {
  width: 1px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  background: rgba(255, 255, 255, 0.2);
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%)
}

#FilterBar .filterLabel {
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.6px;
  text-transform: uppercase
}

#FilterBar .filterSelect {
  display: inline-block;
  width: 180px;
  line-height: normal;
  height: 36px;
  margin-left: 50px;
  color: #002e67;
  font-size: 12px
}

#FilterBar .filterDropdown {
  display: inline-block
}

#FilterBar .filterDropdown .dropdown-toggle {
  line-height: normal !important;
  height: 36px;
  width: 180px;
  margin-left: 50px;
  color: #002e67 !important;
  font-size: 12px !important;
  background-color: #ffffff !important;
  font-weight: normal !important;
  text-transform: none !important
}

#FilterBar .filterDropdown .dropdown-menu {
  text-align: left
}

#FilterBar .filterDropdown .dropdown-item {
  font-size: 12px
}

#FilterBar .filterDropdown .dropdown-item label {
  margin-right: 10px
}

#FilterBar .filterDropdown .dropdown-item.active {
  background-color: #002e67
}

#FilterBar .weekPickerWrapper .react-datepicker__week:hover .react-datepicker__day:not(:nth-child(2)) {
  border-radius: 0.3rem;
  background-color: #f0f0f0
}

#FilterBar .weekPickerWrapper .react-datepicker__week:hover+.react-datepicker__week .react-datepicker__day:nth-child(2) {
  border-radius: 0.3rem;
  background-color: #f0f0f0
}

#FilterBar .weekPickerWrapper .react-datepicker__week:hover .react-datepicker__week-number {
  color: #002e67
}

#FilterBar .weekPickerWrapper .react-datepicker__day:nth-child(2):hover {
  background-color: transparent !important
}

#FilterBar .dropdown-item:active {
  background-color: transparent !important;
  color: inherit !important
}

#FilterBar .react-datepicker__input-container input {
  font-size: 12px
}